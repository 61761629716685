import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class MailerService {

  constructor(private httpClient: HttpClient) { }

  sendMailer(mail: any) {
    console.log('Send Mail fields:', mail);
    return this.httpClient.post('https://sendgridserverless.azurewebsites.net/api/TriggerSendMail?code=Ea69V4SXbb215bT87a-AF1K0Sdl9MqV1ClQ0IKx26tqVAzFuwz4Z4g==', mail, {  responseType: 'text'});
  }
}
