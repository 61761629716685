<div class="banner-image corporate">
  <div class="banner-text">
    <h1>TAILOR MADE FOR CORPORATE CLIENTS</h1>
    <p>Read more on how we can assist you with your sustainability Reporting</p>
    <button class="btn btn-outline-light" routerLink="/contact-us">Contact Us</button>
  </div>
</div>


<div class="container text-center pt-5">
  <h4>INVESTMENT IN PROFESSIONAL EDUCATION</h4>
  <p class="pt-3 text-center">Tailor made for Corporate Clients</p>
</div>

<div class="container ">
  <div class="mx-auto" style="max-width: 540px">
    <app-corporate-card></app-corporate-card>
  </div>
</div>

<div class="container-md pt-5" id="corporate-pricing">
  <table class="pricing-table mb-0 table table-borderless">
    <tr>
      <th class="align-bottom" style="min-width: 100px">
        <div class="card solution-header border-round-top shadow">Solution</div>
      </th>
      <th class="fixed-width">
        <div class="card product-header border-round-top shadow">
          <div class="card-header">
            <div class="pricing-header-icon"><img alt="Interactive Online Education"
                                                  src="https://pssstaticwebstorage.blob.core.windows.net/images/icons/corporate-icon.png"></div>
          </div>
          <div class="card-body">
            <div class="card-title">

              Educate Professionals <br>
              via our industry platforms
            </div>
            <div class="card-subtitle font-italic">Software as a Service for Sponsors</div>
          </div>

        </div>

      </th>
      <th class="fixed-width">
        <div class="card product-header border-round-top shadow">
          <div class="card-header ">
            <div class="pricing-header-icon"><img alt="Interactive Online Education"
                                                  src="https://pssstaticwebstorage.blob.core.windows.net/images/icons/corporate-icon.png"></div>
          </div>
          <div class="card-body">
            <div class="card-title">

              Educate your Staff or Clients<br>
              via your own TRISCOMS&reg; platforms
            </div>
            <div class="card-subtitle font-italic">Platform as a Service for Employers</div>
          </div>

        </div>

      </th>
      <th class="fixed-width">
        <div class="card product-header border-round-top shadow">
          <div class="card-header ">
            <div class="pricing-header-icon"><img alt="Interactive Online Education"
                                                  src="https://pssstaticwebstorage.blob.core.windows.net/images/icons/corporate-icon.png"></div>
          </div>
          <div class="card-body">
            <div class="card-title">

              Educate beneficiaries<br>
              on Public Interest Projects
            </div>
            <div class="card-subtitle font-italic">with SaaS or PaaS</div>
          </div>

        </div>

      </th>
    </tr>
  </table>
  <table class="pricing-table shadow mt-0 table table-borderless ">
    <tr>
      <td class="pricing-icon" style="min-width: 100px">
        <div><img alt="On Demand Online Education"
                  src="https://pssstaticwebstorage.blob.core.windows.net/images/vectors/knowledge-center-vector.png"></div>
        <div class="mt-2">
          On Demand<br>
          Online Education
        </div>
      </td>
      <td class="align-middle fixed-width">
        <b>On Demand, Per Course</b><br>
        Short Courses or Qualifications, Journals, Articles, Newsletters or Knowledge Updates

      </td>
      <td class="align-middle fixed-width">
        <b>25 Courses per year</b><br>
        Journals, Articles, Newsletters or Knowledge Updates
      </td>
      <td class="align-middle fixed-width">
        <b>25 Courses per year</b><br>
        Journals, Articles, Newsletters or Knowledge Updates
      </td>
    </tr>
    <tr class="hr">
      <td>
        <hr>
      </td>
      <td>
        <hr>
      </td>
      <td>
        <hr>
      </td>
      <td>
        <hr>
      </td>
    </tr>
    <tr>
      <td class="pricing-icon">
        <div><img alt="Interactive Online Education"
                  src="https://pssstaticwebstorage.blob.core.windows.net/images/vectors/virtual-campus-vector.png"></div>
        <div class="mt-2">Interactive Online<br> Education</div>
      </td>
      <td class="align-middle">
        <b>Per Session</b><br>
        1 Virtual Training Room<br>
        10 hours of cloud recording.
      </td>
      <td class="align-middle">
        <b>Unlimited per year</b><br>
        1 Virtual Training Room<br>
        10 hours of cloud recording.
      </td>
      <td class="align-middle">
        <b>Unlimited per year</b><br>
        1 Virtual Training Room<br>
        10 hours of cloud recording.
      </td>
    </tr>
    <tr class="hr">
      <td>
        <hr>
      </td>
      <td>
        <hr>
      </td>
      <td>
        <hr>
      </td>
      <td>
        <hr>
      </td>
    </tr>
    <tr>
      <td class="pricing-icon">
        <div><img alt="Face to Face Training Sessions"
                  src="https://pssstaticwebstorage.blob.core.windows.net/images/vectors/event-central-vector.png"></div>
        <div class="mt-2"> Face to Face<br> Training Sessions
        </div>
      </td>
      <td class="align-middle"><b>Per Session</b></td>
      <td class="align-middle"><b>Unlimited per year</b></td>
      <td class="align-middle"><b>Unlimited per year</b></td>
    </tr>
    <tr class="hr">
      <td>
        <hr>
      </td>
      <td>
        <hr>
      </td>
      <td>
        <hr>
      </td>
      <td>
        <hr>
      </td>
    </tr>
    <tr>
      <td class="pricing-icon">
        <div><img alt="Professional Development Portfolio"
                  src="https://pssstaticwebstorage.blob.core.windows.net/images/vectors/individual-vector.png"></div>
        <div class="mt-2">Professional<br>Development Portfolio</div>
      </td>
      <td class="align-middle">
        <div class="checkmark-icon"><img alt="Tick"
                                         src="https://pssstaticwebstorage.blob.core.windows.net/images/icons/checkmark.png"></div>
      </td>
      <td class="align-middle">
        <div class="checkmark-icon"><img alt="Tick"
                                         src="https://pssstaticwebstorage.blob.core.windows.net/images/icons/checkmark.png"></div>
      </td>
      <td class="align-middle">
        <div class="checkmark-icon"><img alt="Tick"
                                         src="https://pssstaticwebstorage.blob.core.windows.net/images/icons/checkmark.png"></div>
      </td>
    </tr>
    <tr class="hr">
      <td>
        <hr>
      </td>
      <td>
        <hr>
      </td>
      <td>
        <hr>
      </td>
      <td>
        <hr>
      </td>
    </tr>
    <tr>
      <td class="pricing-icon">
        <div><img alt="Professional Reporting"
                  src="https://pssstaticwebstorage.blob.core.windows.net/images/vectors/lms-vector.png"></div>
        <div class="mt-2">Professional<br> Reporting</div>

      </td>
      <td class="align-middle">
        <div class="checkmark-icon"><img alt="Tick"
                                         src="https://pssstaticwebstorage.blob.core.windows.net/images/icons/checkmark.png"></div>
      </td>
      <td class="align-middle">
        <div class="checkmark-icon"><img alt="Tick"
                                         src="https://pssstaticwebstorage.blob.core.windows.net/images/icons/checkmark.png"></div>
      </td>
      <td class="align-middle">
        <div class="checkmark-icon"><img alt="Tick"
                                         src="https://pssstaticwebstorage.blob.core.windows.net/images/icons/checkmark.png"></div>
      </td>
    </tr>
    <tr class="hr">
      <td>
        <hr>
      </td>
      <td>
        <hr>
      </td>
      <td>
        <hr>
      </td>
      <td>
        <hr>
      </td>
    </tr>
    <tr>
      <td class="pricing-icon-2">
        <div><img alt="Hosting, support and tech cost"
                  src="https://pssstaticwebstorage.blob.core.windows.net/images/icons/customer-support.png"></div>
        <div class="mt-2">Hosting, support<br> and tech cost</div>
      </td>
      <td class="align-middle">
        <div class="checkmark-icon"><img alt="Tick"
                                         src="https://pssstaticwebstorage.blob.core.windows.net/images/icons/checkmark.png"></div>
      </td>
      <td class="align-middle">
        <div class="checkmark-icon"><img alt="Tick"
                                         src="https://pssstaticwebstorage.blob.core.windows.net/images/icons/checkmark.png"></div>
      </td>
      <td class="align-middle">
        <div class="checkmark-icon"><img alt="Tick"
                                         src="https://pssstaticwebstorage.blob.core.windows.net/images/icons/checkmark.png"></div>
      </td>
    </tr>
    <tr class="hr">
      <td>
        <hr>
      </td>
      <td>
        <hr>
      </td>
      <td>
        <hr>
      </td>
      <td>
        <hr>
      </td>
    </tr>
    <tr>
      <td class="pricing-icon-2">
        <div><img alt="Prices per Delegates"
                  src="https://pssstaticwebstorage.blob.core.windows.net/images/icons/prices-pd.png"></div>
        <div class="mt-2">Prices per Delegates</div>
      </td>
      <td class="text-left price-table-compact">
        <p>
          <b>Module Cost, Per Delegate, Per Course</b><br>
          R350.00 On Demand Online Education<br>
          R350.00 Interactive Education, per Session<br>
        </p>
        <p>
          <b>Face-to-Face Training Session</b><br>
          R250.00 Less than 25 Delegates<br>
          R1000.00 More than 25 Delegates
        </p>

      </td>
      <td class="text-left price-table-compact">
        <b>Module Cost, Per Delegate, Per Month</b>
        <table>
          <tr>
            <td>1-20</td>
            <td>Delegates R107.00</td>
          </tr>
          <tr>
            <td>21-300</td>
            <td>Delegates R90.00</td>
          </tr>
          <tr>
            <td>301-600</td>
            <td>Delegates R70.00</td>
          </tr>
          <tr>
            <td>651-1800</td>
            <td>Delegates R48.00</td>
          </tr>
          <tr>
            <td>1801-4000</td>
            <td>Delegates R26.00</td>
          </tr>
          <tr>
            <td>4000+</td>
            <td>Delegates R15.00</td>
          </tr>
        </table>
        <p class="font-italic">* Minimum Monthly Fees Apply</p>
      </td>
      <td class="text-left price-table-compact">
        <b>Module Cost, Per Delegate, Per Month</b>
        <table>
          <tr>
            <td>1-20</td>
            <td>Delegates R107.00</td>
          </tr>
          <tr>
            <td>21-300</td>
            <td>Delegates R90.00</td>
          </tr>
          <tr>
            <td>301-600</td>
            <td>Delegates R70.00</td>
          </tr>
          <tr>
            <td>651-1800</td>
            <td>Delegates R48.00</td>
          </tr>
          <tr>
            <td>1801-4000</td>
            <td>Delegates R26.00</td>
          </tr>
          <tr>
            <td>4000+</td>
            <td>Delegates R15.00</td>
          </tr>
        </table>
        <p class="font-italic">* Minimum Monthly Fees Apply</p>
      </td>
    </tr>
  </table>


  <div class="d-flex justify-content-center mt-5 bg-white flex-wrap">

    <img alt="" class="m-3" src="https://pssstaticwebstorage.blob.core.windows.net/images/logos/triscoms-logo-corporate.png"
         style="height: 45px;">
    <img alt="" class="m-3" src="https://pssstaticwebstorage.blob.core.windows.net/images/logos/ed-online.png"
         style="height: 45px;">
  </div>

</div>


<div class=" d-flex justify-content-center my-5 bg-white">

  <img alt="" class="m-3" src="https://pssstaticwebstorage.blob.core.windows.net/images/logos/azure-logo.png"
       style="height: 50px;">
  <img alt="" class="m-3" src="https://pssstaticwebstorage.blob.core.windows.net/images/logos/microsoft-partner.png"
       style="height: 50px;">
</div>
<div class="container p-5 my-5 border-box">
  <app-logo-carousel key="corporate"></app-logo-carousel>
</div>
<div class="container-fluid goals-banner">
  <div class="text-center py-2">

    <img alt="" class="my-5" src="https://pssstaticwebstorage.blob.core.windows.net/images/logos/sustainable-development-logo.png"
         style="height: 70px; max-width: 90%">
    <div class="container pb-5">
      <h4>Detailed Analytics for your Sustainability Report</h4>
      <h4 class="mb-0"><a class="contact-us-link" href="#" routerLink="/contact-us">CONTACT US</a></h4>
    </div>
  </div>
</div>
