<div class="banner-image">
  <div class="banner-text">
    <h1>CONTACT US</h1>
    <p>We would love to hear from you so let's get in touch!</p>
  </div>
</div>
<div class="container pt-5 text-center">

  <h5 class="">Contact Us</h5>
  <p class="pt-3 text-center">
    We would love to hear from you so let's get in touch!
  </p>

  <div class="row">
    <div class="col-md-8 py-2">
      <form action='' method="post" id="uploadForm" #contactForm="ngForm" [formGroup]="myForm">

          <fieldset>
            <ol class="d-flex flex-wrap pl-0">
              <div class="col-md-6 d-flex align-items-end mt-2">
                <li class="form-group white-form col-md-12 px-0">
                  <span class="obligatory_star_i18n">required</span>
                  <label class="label-mobile label-hidden" for="name">Your full name</label>
                  <input class="form-control" required="true" formControlName="name" id="name" name="name" placeholder="Your full name" title="Your full name" type="text" />
                  <div style="clear:both;"></div>
                </li>
              </div>
              <div class="col-md-6 d-flex align-items-end mt-2">
                <li class="form-group white-form col-md-12 px-0">
                  <label class="label-mobile label-hidden" for="company">Your company</label>
                  <input class="form-control" formControlName="company" id="company" name="company" placeholder="Your company" title="Your company" type="text" />
                  <div style="clear:both;"></div>
                </li>
              </div>
              <div class="col-md-6 d-flex align-items-end mt-2">
                <li class="form-group white-form col-md-12 px-0">
                  <label class="label-mobile label-hidden" for="contact">Your contact number</label>
                  <input class="form-control" formControlName="contact" id="contact" name="contact" placeholder="Your contact number" title="Your contact number" type="text" />
                  <div style="clear:both;"></div>
                </li>
              </div>
              <div class="col-md-6 d-flex align-items-end mt-2">
                <li class="form-group white-form col-md-12 px-0">
                  <span class="obligatory_star_i18n">required</span>
                  <label class="label-mobile label-hidden" for="email">Your email address</label>
                  <input class="form-control" required="true" formControlName="email" id="email" name="email" email="true" placeholder="Your email address" title="Your email address" type="text" />
                  <div style="clear:both;"></div>
                </li>
              </div>
              <div class="col-md-12 d-flex align-items-end mt-2">
                <li class="form-group white-form col-md-12 px-0">
                  <label class="label-mobile label-hidden" for="message">How can we help you</label>
                  <textarea class="form-control" formControlName="message" cols="40" id="message" name="message:" placeholder="How can we help you" rows="10" title="How can we help you"></textarea>
                  <div style="clear:both;"></div>
                </li>
              </div>
              <recaptcha
                [size]="'invisible'"
                (captchaResponse)="onCaptchaResponse($event)"
                (captchaExpired)="onCaptchaExpired($event)">
              </recaptcha>
              <div class="col-md-12 d-flex align-items-end mt-2">
                <li class="preloader not_xhr">
                  <button type="button" (click)="sendMail()"  [disabled]="contactForm.invalid" class="btn btn-dark rounded-0 m-2" id="send-button">SEND MESSAGE</button>
                </li>
              </div>
            </ol>
          </fieldset>
        </form>

    </div>
    <div class="col-md-4 text-left pl-5 py-2">
      +27 12 111 7000 | <a href="mailto:support@ps.studio">support@ps.studio</a><br><br>

      Menlyn Central Towers<br>
      10th Floor, 125 Dallas Avenue<br>
      Menlyn Maine<br>
      Pretoria<br>
      Gauteng<br>
      0081
    </div>
  </div>
</div>
<script src='https://leads-capturer.futuresimple.com/static/js/jquery-1.10.2.min.js' ></script>
<script src='https://leads-capturer.futuresimple.com/static/js/jquery.placeholder.js' ></script>
<script src='https://leads-capturer.futuresimple.com/static/chosen/chosen.jquery.min.js' ></script>
<script src='https://leads-capturer.futuresimple.com/static/js/form_embedded.min.js' ></script>
<script src='https://leads-capturer.futuresimple.com/static/js/report_size.min.js' ></script>
<script src='https://leads-capturer.futuresimple.com/static/datetimepicker/jquery.datetimepicker.min.js' ></script>

<iframe
  allowfullscreen
  class="mt-4"
  frameborder="0" height="450"
  src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14369.714603917188!2d28.326596!3d-25.7894289!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xa532504eaaf6f995!2sPioneering+Solutions+Studio!5e0!3m2!1sen!2sza!4v1551794703366"
  style="border:0" width="100%"></iframe>

