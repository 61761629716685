import {Component, OnInit} from '@angular/core';
import {TimelineItem} from '../timeline-item';

@Component({
  selector: 'app-timeline',
  templateUrl: './timeline.component.html',
  styleUrls: ['./timeline.component.scss']
})
export class TimelineComponent implements OnInit {

  items: TimelineItem[] = [
    {
      label: 'Medical Practice Consulting',
      content: 'Our story begins with Medical Practice Consulting (Pty) Ltd (MPC) and countless caffeinated nights brainstorming' +
        ' and planning the launch of the largest online medical education platform in Africa. <i>Not to spoil the ending, but we ' +
        'pulled it off!.</i>',
      image: 'history-2010-image.png',
      imageStatic: '2010',
      banner: 'history-2010-banner.png',
      year: '2010'
    }, {
      label: 'MPC launches EdTech',
      content: 'MPC officially launches TRISCOMS&reg; Version 1.0 and its first professional industry EdTech product supporting ' +
        'only 238 healthcare professionals in the first year. <i>It was tough for a tech company to only subscribe 238 ' +
        'in its first year, but we kept innovating.</i>',
      image: 'history-2011-image.png',
      imageStatic: '2011',
      banner: 'history-2011-banner.png',
      year: '2011'
    }, {
      label: 'MPC joins the Foundation for Professional Development (FPD)',
      content: 'MPC joins the Foundation for Professional Development (FPD) and becomes part of a Group registered with the South African' +
        ' Council for Higher Education. We continue to grow our professional user database and we are up to 26,000.',
      image: 'history-2015-image.png',
      imageStatic: '2013',
      banner: 'history-2015-banner.png',
      year: '2013'
    }, {
      label: 'MPC Expands to 41 000 professionals',
      content: 'MPC is awarded the South African Institute for Industrial Engineering President’s Award for ' +
        '“System Design and Implementation” and one of our Founders, Werner Swanepoel, is recognised as one of the Top 35' +
        ' Chartered Accountants in South Africa under the age of 35. ',
      image: 'history-2015-image.png',
      imageStatic: '2014',
      banner: 'history-2015-banner.png',
      year: '2014'
    }, {
      label: 'TRISCOMS&reg; Expands to 41 000 professionals',
      content: 'TRISCOMS&reg; expands to support over 41,000 healthcare professionals from over 100 countries.',
      image: 'history-2015-image.png',
      imageStatic: '2015',
      banner: 'history-2015-banner.png',
      year: '2015'
    }, {
      label: 'MPC Changes it\'s Name',
      content: 'MPC changes its name to “Pioneering Solutions Studio” (PSS) allowing expansion into other industries ' +
        'for the TRISCOMS&reg; platform. PSS also starts supporting FPD with the development of Medical Technology (MedTech)' +
        ' solutions to help in the fight against HIV.',
      image: 'history-2016-image.png',
      imageStatic: '2016',
      banner: 'history-2016-banner.png',
      year: '2016'
    }, {
      label: 'FSI is founded',
      content: 'Foundation for Sustainable Innovation (FSI) is co-founded by PSS with the idea to establish a Microsoft Academy ' +
        'and an innovative FinTech arm to the group.',
      image: 'history-2017-1-image.png',
      imageStatic: '2017-1',
      banner: 'history-2017-1-banner.png',
      year: '2017'
    }, {
      label: 'Qode Health Solutions',
      content: 'Qode Health Solutions is co-founded with Right to Care with the goal to revolutionise patient care and increase health ' +
        'outcomes in developing countries. <i>The MedTech that PSS started developing in 2016 was transferred to Qode, ' +
        'allowing PSS to focus on EdTech and Qode to specialise in MedTech.</i>',
      image: 'history-2017-2-image.png',
      imageStatic: '2017-2',
      banner: 'history-2017-2-banner.png',
      year: '2017'
    }, {
      label: 'Qode Health Solutions',
      content: 'Qode Health Solutions expands and its applications support over 2 million community and facility-based HIV tests. ' +
        '<i>The use of technology for social impact and for good is why we work at PSS.</i>',
      image: 'history-2018-1-image.png',
      imageStatic: '2018-1',
      banner: 'history-2018-1-banner.png',
      year: '2018'
    }, {
      label: 'Top 2 Health Data Management',
      content: 'Qode Health Solutions and its software ranks as one of the top 2 Health Information Management System (HIMS) in ' +
        'South Africa by an assessment commissioned by USAID and CDC. <i>Our baby is all grown up!</i>',
      image: 'history-2018-2-image.png',
      imageStatic: '2018-2',
      banner: 'history-2018-2-banner.png',
      year: '2018'
    }, {
      label: 'PSS launches TRISCOMS&reg;',
      content: 'PSS launches TRISCOMS&reg; as ED.online for professional industries outside of healthcare.<i>Supply and Demand – Economics 101.</i>',
      image: 'history-2018-2-image.png',
      imageStatic: '2018-3',
      banner: 'history-2018-2-banner.png',
      year: '2018'
    }, {
      label: 'FSI Co-founds Bitfarming',
      content: 'FSI co-founds BitFarming. A world-first (for as far as we know),  BitFarming is a new pioneering South African livestock market ' +
        'agent with a unique business model that enables any person to buy livestock on any scale and resell it for a guaranteed profit' +
        ' within a minimum prescribed cycle. <i>This was achieved through the application of some of FSI’s FinTech. ' +
        'Now, even New Yorkers can own cattle on a farm in Africa!</i>',
      image: 'history-2019-image.png',
      imageStatic: '2019',
      banner: 'history-2019-banner.png',
      year: '2019'
    }, {
      label: 'PSS Launches Microsoft Digital Literacy Programme',
      content: 'PSS Launches Microsoft Digital Literacy Programme in conjunction with Standard Bank to be provided for free' +
        ' on national level to all South Africans. <i>True story – no strings attached.</i>',
      image: 'history-2020-image.png',
      imageStatic: '2020',
      banner: 'history-2020-banner.png',
      year: '2020'
    }];

  constructor() {
  }

  ngOnInit(): void {

  }


}
