import {Component, OnInit} from '@angular/core';
import {faFacebookF} from '@fortawesome/free-brands-svg-icons/faFacebookF';
import {faLinkedinIn} from '@fortawesome/free-brands-svg-icons/faLinkedinIn';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  faFacebookF = faFacebookF;
  faLinkedIn = faLinkedinIn;

  constructor() {
  }

  ngOnInit(): void {
  }

}
