<div class="banner-image">
  <div class="banner-text">
    <h1>OUR VALUE ADDED SERVICES</h1>
    <p>The A to Z of Professional Education</p>
  </div>
</div>
<div class="container py-5 text-center">

  <h4 class="text-center">VALUE ADDED SERVICES</h4>
  <p class="pt-3 text-center">
    In addition to the provision of TRISCOMS&reg; and all its functionality, PSS further supports Professional Education
    Partners and Corporates with the following value-added services.
  </p>

</div>

<div class="container-fluid background-dark" id="instructional-course-design">
  <div class="container py-4">
    <div
      class="d-flex flex-row flex-wrap-reverse flex-lg-nowrap flex-xl-nowrap justify-content-center align-items-center">
      <div class="mx-2 my-4 mx-lg-4 ">
        <h4 class="position-relative">
          <div class="icon-box">
            <img src="https://pssstaticwebstorage.blob.core.windows.net/images/icons/instructional-course-design-icon.png" alt="Instructional Design Icon">
          </div>
          <div class="align-bottom header-text">Instructional Design for content creation</div>
        </h4>
        <p class="pt-3">
          We offer in-house Instructional Design Services to Professional Education Partners and Corporates that select
          Bring Your Own Content (BYOC) to ensure online learning experiences and material that results in the
          acquisition and application of knowledge and skills. Content provided is reworked based on international
          e-learning best practices and methodology to provide learners engaging and interactive training material for
          the most effective learning experiences.
        </p>
        <p class="pt-3">
          To enable Professional Education Partners and Corporates to
          efficiently and effectively create their own content, we can issue iSpring Suite or Adobe Captivate licences
          (at cost) to facilitate content creation within the correct format (SCORM 1.2) via a simple Microsoft
          PowerPoint plug-in or we can perform all aspects of instructional design.
        </p>

      </div>
      <div class="py-5 text-center">

        <img alt="Instructional Design" class="service-image"
             src="https://pssstaticwebstorage.blob.core.windows.net/images/vectors/instructional-design.png" alt="Instructional Design Icon">

      </div>
    </div>
  </div>
</div>


<div class="container-fluid background-light" id="videography-editing">
  <div class="container py-4">
    <div class="d-flex flex-row flex-wrap flex-lg-nowrap flex-xl-nowrap justify-content-center align-items-center">
      <div class="py-5 text-center">

        <img alt="Instructional Design" class="service-image" src="https://pssstaticwebstorage.blob.core.windows.net/images/vectors/videography.png" alt="Videography Icon">

      </div>
      <div class="mx-2 my-4 mx-lg-4">
        <h4 class="position-relative">
          <div class="icon-box">
            <img class="img-filter" src="https://pssstaticwebstorage.blob.core.windows.net/images/icons/videography-services.png" alt="Videography Icon">
          </div>
          <div class="align-bottom header-text">Videography Services</div>
        </h4>
        <p class="pt-3">
          Professional Education Partners and Corporates make use of the PSS videography services to create video-based
          learning resources that enhances OnDemand Online Learning material. The use of video material within online
          education is recommended as it plays an integrate role in the absorption of learning content by Professionals.

        </p>
        <p class="pt-3">
          With a fully equipped recording studio in Pretoria, South Africa, and a fulltime video editing team, content
          providers can create video-based material aligned with international best practices.
        </p>

      </div>

    </div>
  </div>
</div>


<div class="container-fluid background-dark" id="accredited-content-sourcing">
  <div class="container py-4">
    <div
      class="d-flex flex-row flex-wrap-reverse flex-lg-nowrap flex-xl-nowrap justify-content-center align-items-center">

      <div class="mx-2 my-4 mx-lg-4 ">
        <h4 class="position-relative">
          <div class="icon-box">
            <img src="https://pssstaticwebstorage.blob.core.windows.net/images/icons/accredited-content-sourcing-icon.png" alt="CPD Accreditation of learning material Icon">
          </div>
          <div class="align-bottom header-text">CPD Accreditation of learning material</div>
        </h4>
        <p class="pt-3">
          Our team assists Professional Education Partners and Corporates in getting their own learning material CPD
          accredited in accordance with the rules and regulations of their respective industry’s governing bodies or
          councils.
        </p>
        <h5>Licenced Content</h5>
        <p class="pt-3">Sourced Content from our Professional Education Partners PSS assists Corporates in the
          sourcing of course material or content from our many Professional Education Partners. We can even have custom
          courses or learning material developed for Corporate-specific requirements. </p>
      </div>

      <div class="py-5 text-center">

        <img alt="Instructional Design" class="service-image" src="https://pssstaticwebstorage.blob.core.windows.net/images/vectors/cpd-accreditation.png" alt="Instructional Design Icon">
      </div>

    </div>
  </div>
</div>

<div class="container-fluid background-light" id="education">
  <div class="container py-4">
    <div class="d-flex flex-row flex-wrap flex-lg-nowrap flex-xl-nowrap justify-content-center align-items-center">
      <div class="py-5 text-center">

        <img alt="Instructional Design" class="service-image" src="https://pssstaticwebstorage.blob.core.windows.net/images/vectors/game-development.png" alt="Instructional Design Icon">

      </div>
      <div class="mx-2 my-4 mx-lg-4 ">
        <h4 class="position-relative">
          <div class="icon-box">
            <img class="img-filter" src="https://pssstaticwebstorage.blob.core.windows.net/images/icons/game-development.png" alt="Game Development Icon">
          </div>
          <div class="align-bottom header-text">Game Development</div>
        </h4>
        <p class="pt-3">
          Our developers build educational training programmes through a new, fun, and interactive 2D or 3D gaming
          experience, based on the Unity Games Engine. By focusing on a well-designed and highly entertaining learning
          experience, learning becomes more enjoyable and engaging resulting in better results in less time.
        </p>

      </div>

    </div>
  </div>
</div>


<div class="container-fluid background-dark" id="graphic-design">
  <div class="container py-4">
    <div
      class="d-flex flex-row flex-wrap-reverse flex-lg-nowrap flex-xl-nowrap justify-content-center align-items-center">

      <div class="mx-2 my-4 mx-lg-4 ">
        <h4 class="position-relative">
          <div class="icon-box">
            <img src="https://pssstaticwebstorage.blob.core.windows.net/images/icons/graphic-design-icon.png" alt="Graphic Design Icon">
          </div>
          <div class="align-bottom header-text">Graphic Design Services</div>
        </h4>
        <p class="pt-3">
          Graphic design is an important tool that helps you communicate your brands story to Professionals and the
          public. Our team of creative professionals help Professional Education Partners and Corporates alike elevate
          their educational brand to effectively reach desired target audiences. First impressions are crucial in
          marketing, which is why our team will work with you to ensure that your brands message is consistent in every
          communication to enhance credibility and retain existing and new clients.
        </p>

      </div>

      <div class="py-5 text-center">

        <img alt="Instructional Design" class="service-image" src="https://pssstaticwebstorage.blob.core.windows.net/images/vectors/graphic-design.png" alt="Graphic Design Icon">
      </div>

    </div>
  </div>
</div>

