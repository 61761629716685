import {Component, OnInit} from '@angular/core';
import {CaptureMpcFormService} from '../capture-mpc-form.service';
import {FormBuilder, FormGroup} from '@angular/forms';
import {Meta, Title} from '@angular/platform-browser';
import {JsonLdService} from '../shared/json-ld.service';
import {Router} from '@angular/router';
import {HttpClient} from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';
import { Mail } from '../services/mailer/mail';

@Component({
  selector: 'app-page-capture-mpc-form',
  templateUrl: './page-capture-mpc-form.component.html',
  styleUrls: ['./page-capture-mpc-form.component.scss']
})
export class PageCaptureMpcFormComponent implements OnInit {

  public uploadForm: FormGroup;
  errors = [];
  public productId: any;
  public productName: string;
  public productSku: string;

  constructor(
    private service: CaptureMpcFormService,
    private formBuilder: FormBuilder,
    private httpClient: HttpClient,
    private titleService: Title,
    private metaService: Meta,
    private router: Router,
    private route: ActivatedRoute,
    private jsonLdService: JsonLdService) {

    titleService.setTitle('Standard Bank Financing Capture Form');
    // console.log(this.router.url);
    this.route.queryParams.subscribe(params => {
      this.productId = params.Product_ID;
      this.productName = params.Product_Name;
      this.productSku = params.Product_SKU;
    });

  }

  ngOnInit(): void {
    this.uploadForm = this.formBuilder.group({
      form_token: [''],
      lang: [''],
      code: [''],
      created_via: [''],
      fullName: [''],
      surname: [''],
      contact: [''],
      email: [''],
      between: [''],
      andDate: [''],
      weekdays: [''],
      weekend: [''],
      method_email: [''],
      telephone: [''],
      productId: [''],
      productSku: [''],
      productName: [''],
      disclaimer: [''],
      suburb: [''],
      province: [''],
      service: [''],
      message: ['']
    });

  }

  send() {
    
    var data = {
      'fullName' : this.uploadForm.get('fullName').value,
      'surname': this.uploadForm.get('surname').value,
      'contact': this.uploadForm.get('contact').value,
      'email': this.uploadForm.get('email').value,
      'andDate': this.uploadForm.get('andDate').value,
      'between': this.uploadForm.get('between').value,
      'weekdays': this.uploadForm.get('weekdays').value,
      'weekend': this.uploadForm.get('weekend').value,
      'method_email': this.uploadForm.get('method_email').value,
      'telephone': this.uploadForm.get('telephone').value,
      'productId':  this.productId,
      'productSku': this.productSku,
      'productName': this.productName,
      'suburb': this.uploadForm.get('suburb').value,
      'province': this.uploadForm.get('province').value
    }
    
    this.service.submitCaptureForm(data).subscribe(
      (res) => {
        this.router.navigate(['finance-thank-you']);
      },
      (err) => console.log(err)
    );


  }
}
