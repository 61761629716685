import {Component} from '@angular/core';
import {Meta, Title} from '@angular/platform-browser';
import {JsonLdService} from '../shared/json-ld.service';
import {Location} from '@angular/common';

@Component({
  selector: 'app-page-not-found',
  templateUrl: './page-not-found.component.html',
  styleUrls: ['./page-not-found.component.scss']
})
export class PageNotFoundComponent {

  constructor(private titleService: Title, private metaService: Meta, private jsonLdService: JsonLdService, private locationService: Location) {

    titleService.setTitle('PSS - Page not found');

    // jsonLdService.updateJson({
    //   '@context': 'http://schema.org',
    //   '@type': 'WebSite',
    //   name: 'education',
    //   url: 'https://angular.io'
    // });
    //
    // metaService.addTags([
    //   {name: 'keywords', content: 'Angular, Universal, Example'},
    //   {name: 'description', content: 'Angular Universal Example'},
    //   {name: 'robots', content: 'index, follow'}
    // ]);
  }

  goBack() {
    this.locationService.back();
  }
}
