import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {HeaderComponent} from './header/header.component';
import {FooterComponent} from './footer/footer.component';
import {PageHomeComponent} from './page-home/page-home.component';
import {PageAboutUsComponent} from './page-about-us/page-about-us.component';
import {PageFunctionalityComponent} from './page-functionality/page-functionality.component';
import {PageServicesComponent} from './page-services/page-services.component';
import {PageIndustriesComponent} from './page-industries/page-industries.component';
import {PageCorporateComponent} from './page-corporate/page-corporate.component';
import {PageEducationComponent} from './page-education/page-education.component';
import {PageContactUsComponent} from './page-contact-us/page-contact-us.component';
import {PageCookieUsageComponent} from './page-cookie-usage/page-cookie-usage.component';
import {PageDisclaimerComponent} from './page-disclaimer/page-disclaimer.component';
import {PageTermsAndConditionsComponent} from './page-terms-and-conditions/page-terms-and-conditions.component';
import {PagePrivacyClausComponent} from './page-privacy-claus/page-privacy-claus.component';
import {PageAppUserAgreementComponent} from './page-app-user-agreement/page-app-user-agreement.component';
import {TimelineComponent} from './timeline/timeline.component';
import {IndustryTileComponent} from './industry-tile/industry-tile.component';
import {CorporateCardComponent} from './corporate-card/corporate-card.component';
import {EducationCardComponent} from './education-card/education-card.component';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {ViewportScroller} from '@angular/common';
import {Event, Router, Scroll} from '@angular/router';
import {filter, pairwise} from 'rxjs/operators';
import {ReactiveFormsModule} from '@angular/forms';
import {HttpClientModule} from '@angular/common/http';
import {WINDOW_PROVIDERS} from './window.service';
import {InViewportModule} from '@thisissoon/angular-inviewport';
import {TimelineItemComponent} from './timeline-item/timeline-item.component';
import {CarouselModule} from 'ngx-owl-carousel-o';
import {LogoCarouselComponent} from './logo-carousel/logo-carousel.component';
import {NgxJsonLdModule} from '@ngx-lite/json-ld';
import {PageNotFoundComponent} from './page-not-found/page-not-found.component';
import {PageThankYouComponent} from './page-thank-you/page-thank-you.component';
import {NgcCookieConsentConfig, NgcCookieConsentModule} from 'ngx-cookieconsent';
import {DeferLoadModule} from '@trademe/ng-defer-load';
import {environment} from '../environments/environment';
import {PagePrivacyNoticeComponent} from './page-privacy-notice/page-privacy-notice.component';
import {PageCaptureMpcFormComponent} from './page-capture-mpc-form/page-capture-mpc-form.component';
import {ReCaptchaModule} from 'angular-recaptcha3';
import {PageFinanceThankYouComponent} from './page-finance-thank-you/page-finance-thank-you.component';
import { PageHiddenContactUsComponent } from './page-hidden-contact-us/page-hidden-contact-us.component';

const cookieConfig: NgcCookieConsentConfig = {
  cookie: {
    domain: environment.domain
  },
  palette: {
    popup: {
      background: '#27273C'
    },
    button: {
      background: '#ADBD2E'
    }
  },
  position: 'bottom-left',
  theme: 'edgeless',
  type: 'opt-out',
  layout: 'my-custom-layout',
  layouts: {
    'my-custom-layout': '{{messagelink}}{{compliance}}'
  },
  elements: {
    messagelink: `
    <span id="cookieconsent:desc" class="cc-message">{{message}}
        <a aria-label="learn more about cookies" tabindex="0" class="cc-link" href="{{cookiePolicyHref}}" target="_blank">{{cookiePolicyLink}}</a> and our
        <a aria-label="learn more about our terms of service" tabindex="0" class="cc-link" href="{{tosHref}}" target="_blank">{{tosLink}}</a>
    </span>
    `,
  },
  content: {
    message: 'By using our site, you acknowledge that you have read and understand our ',

    cookiePolicyLink: 'Cookie Policy',
    cookiePolicyHref: '/cookie-usage',


    tosLink: 'Terms & Conditions',
    tosHref: '/terms-conditions',
  }
};


@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    PageHomeComponent,
    PageAboutUsComponent,
    PageFunctionalityComponent,
    PageServicesComponent,
    PageIndustriesComponent,
    PageCorporateComponent,
    PageEducationComponent,
    PageContactUsComponent,
    PageCookieUsageComponent,
    PageDisclaimerComponent,
    PageTermsAndConditionsComponent,
    PagePrivacyClausComponent,
    PageAppUserAgreementComponent,
    TimelineComponent,
    IndustryTileComponent,
    CorporateCardComponent,
    EducationCardComponent,
    TimelineItemComponent,
    LogoCarouselComponent,
    PageNotFoundComponent,
    PageThankYouComponent,
    PageFinanceThankYouComponent,
    PagePrivacyNoticeComponent,
    PageCaptureMpcFormComponent,
    PageHiddenContactUsComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    CarouselModule,
    HttpClientModule,
    BrowserAnimationsModule,
    FontAwesomeModule,
    ReactiveFormsModule,
    NgxJsonLdModule,
    InViewportModule,
    NgcCookieConsentModule.forRoot(cookieConfig),
    ReCaptchaModule.forRoot({
      invisible: {
        sitekey: environment.site_key,
      },
      normal: {
        sitekey: environment.site_key,
      },
      language: 'en'
    }),
    DeferLoadModule,
  ],
  providers: [
    WINDOW_PROVIDERS,
  ],
  bootstrap: [AppComponent]
})
export class AppModule {

  constructor(private router: Router, private viewportScroller: ViewportScroller) {
    this.handleScrollOnNavigation();
  }

  /**
   * When route is changed, Angular interprets a simple query params change as "forward navigation" too.
   * Using the pairwise function allows us to have both the previous and current router events, which we can
   * use to effectively compare the two navigation events and see if they actually change route, or only
   * the route parameters (i.e. selections stored in query params).
   *
   * Related to: https://github.com/angular/angular/issues/26744
   */
  private handleScrollOnNavigation(): void {
    this.router.events.pipe(
      // import { Event } from '@angular/router'
      filter((e: Event): e is Scroll => e instanceof Scroll),
      pairwise()
    ).subscribe((e: Scroll[]) => {
      const previous = e[0];
      const current = e[1];
      if (current.position) {
        // Backward navigation
        this.viewportScroller.scrollToPosition(current.position);
      } else if (current.anchor) {
        // Anchor navigation
        this.viewportScroller.scrollToAnchor(current.anchor);
      } else {
        // Check if routes match, or if it is only a query param change
        if (this.getBaseRoute(previous.routerEvent.urlAfterRedirects) !== this.getBaseRoute(current.routerEvent.urlAfterRedirects)) {
          // Routes don't match, this is actual forward navigation
          // Default behavior: scroll to top
          this.viewportScroller.scrollToPosition([0, 0]);
        }
      }
    });
  }

  private getBaseRoute(url: string): string {
    // return url without query params
    return url.split('?')[0];
  }
}
