<div class="container">

  <div class="mt-5 pt-3 alert alert-success">

    <p class=" text-center">
      Your application has been sent successfully!
    </p>
    <p class=" text-center">
      A member of our team will get in touch with you as soon as possible.

    </p>

  </div>


</div>
