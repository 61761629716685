<article class="full">
  <div class="container">
    <div class="row my-4 bg-dna-strands">
      <div class="col-12 col-lg-9 offset-lg-3 col-xl-10 offset-xl-2 py-2">
        <h2>Page not found.</h2>
        <p>Sorry, we are unable to find the page you are looking for.</p>
        <p>
          <button class="btn btn-primary" (click)="goBack()" type="button">Go back</button>
        </p>
      </div>
    </div>
  </div>
</article>
