<div class="card product-card shadow-lg border-round-top">
  <div class="card-header text-center brand-green-blue border-round-top">PROFESSIONAL EDUCATION PARTNERS</div>

  <picture class="card-img-top rounded-0" style="height: 129px">
    <source srcset="https://pssstaticwebstorage.blob.core.windows.net/images/banners/education-partners.webp" type="image/webp">
    <source srcset="https://pssstaticwebstorage.blob.core.windows.net/images/banners/education-partners.png" type="image/png">
    <img alt="Education" class="card-img-top rounded-0" style="height: 129px" src="https://pssstaticwebstorage.blob.core.windows.net/images/banners/education-partners.png">
  </picture>
  <div class="card-img-overlay">
    <img alt="Education Icon" src="https://pssstaticwebstorage.blob.core.windows.net/images/icons/education-2-icon.png">
  </div>
  <div class="card-group m-0 ">
    <div class="card m-0 brand-dark border-0 rounded-0 inner-card">
      <div class="card-body border-right-white">

        <div class="card-title text-center ">Educate Professionals via our industry platforms</div>
        <div class="card-subtitle text-center">Software as a Service for Educators</div>
        <hr class="w-65 bg-grey">

        <div class="card-text" style="height: 400px">
          <ul class="triangle text-left">
            <li>Use TRISCOMS&reg; ED.online to sell your content to our Professional databases.</li>
            <li>Content may or may not be CPD-accredited.</li>
            <li>Offer your content to our Corporate Clients for staff training.</li>
            <li>Includes all hosting, support, and tech cost.</li>
          </ul>
        </div>
      </div>
      <div class="card-footer  text-center bg-white border-right-blue-green rounded-0">
        <img alt="" class=" rounded-0 mx-auto" src="https://pssstaticwebstorage.blob.core.windows.net/images/logos/ed-online.png"
             style="height: 50px;">
      </div>
    </div>
    <div class="card m-0 brand-dark border-0 rounded-0  inner-card">
      <div class="card-body">
        <div class="card-title text-center">Educate Professionals via your own TRISCOMS&reg; platform
        </div>
        <div class="card-subtitle text-center">Platform as a Service for Educators</div>
        <hr class="w-65 bg-light">
        <div class="card-text">
          <ul class="triangle text-left">
            <li>Use TRISCOMS&reg; to sell your content to your own databases.</li>
            <li>Only pay when you sell**.</li>
            <li>Content may or may not be CPD-accredited.</li>
            <li>Integrate your TRISCOMS&reg; platform with the TRISCOMS&reg; community</li>
            <li>Includes all hosting, support and tech cost.</li>
          </ul>
        </div>
      </div>
      <div class="card-footer  text-center bg-white">
        <img alt="" class=" rounded-0 mx-auto" src="https://pssstaticwebstorage.blob.core.windows.net/images/logos/triscoms-logo-education.png"
             style="height: 50px;">

      </div>
    </div>
  </div>

  <div class="card-footer text-center">
    Donate Content to Public Interest Projects.<br>
    <span class="font-italic">Donate open access to your developed content on Public Interest Projects</span>
  </div>
  <div class="stripe bg-white"></div>
  <div class="stripe brand-dark"></div>
</div>
