<script src="footer.component.ts"></script>

<div class="footer">

  <div class="container-lg py-5">
    <div class="card-deck d-flex flex-wrap">
      <div class="card col-md-4 border-bottom-0 border-top-0 border-right-0">
        <div class="card-body py-0 pl-3 pr-0">
          <div class="card-title">
            <div class="heading">OUR GROUP</div>
          </div>
          <div class="d-flex flex-wrap flex-sm-wrap  flex-lg-nowrap">
            <div class="logo  p-2">

              <a href="https://qode.healthcare/contact-us" rel="noreferrer noopener" target="_blank">
                <picture>
                  <source srcset="https://pssstaticwebstorage.blob.core.windows.net/images/logos/qode-logo.webp" type="image/webp">
                  <source srcset="https://pssstaticwebstorage.blob.core.windows.net/images/logos/qode-logo.png" type="image/png">
                  <img alt="Qode Health Solutions" class="card-img" src="https://pssstaticwebstorage.blob.core.windows.net/images/logos/qode-logo.png">
                </picture>
              </a>
            </div>

            <div class="logo  p-2">
              <a routerLink="">
                <picture>
                  <source srcset="https://pssstaticwebstorage.blob.core.windows.net/images/logos/pss-logo.webp" type="image/webp">
                  <source srcset="https://pssstaticwebstorage.blob.core.windows.net/images/logos/pss-logo.png" type="image/png">
                  <img alt="Pioneering Solution Studio" class="card-img" src="https://pssstaticwebstorage.blob.core.windows.net/images/logos/pss-logo.png">
                </picture>
              </a>
            </div>

          </div>
        </div>
      </div>
      <div class="card col-md-8 border-bottom-0 border-top-0 border-right-0">
        <div class="card-body py-0 pl-3 pr-0">
          <div class="card-title">
            <div class="heading">OUR CERTIFICATION</div>
          </div>
          <div class="d-flex flex-wrap  flex-sm-wrap  flex-lg-nowrap">
            <div class="logo p-2">
              <picture>
                <source srcset="https://pssstaticwebstorage.blob.core.windows.net/images/logos/aep-microsoft-logo.webp" type="image/webp">
                <source srcset="https://pssstaticwebstorage.blob.core.windows.net/images/logos/aep-microsoft-logo.png" type="image/png">
                <img alt="Microsoft Authorized Education Partner" class="card-img "
                     src="https://pssstaticwebstorage.blob.core.windows.net/images/logos/aep-microsoft-logo.png">
              </picture>
            </div>
            <div class="logo p-2">
              <picture>
                <source srcset="https://pssstaticwebstorage.blob.core.windows.net/images/logos/microsoft-imagine-academy.webp" type="image/webp">
                <source srcset="https://pssstaticwebstorage.blob.core.windows.net/images/logos/microsoft-imagine-academy.png" type="image/png">
                <img alt="Microsoft Imagine Academy" class="card-img "
                     src="https://pssstaticwebstorage.blob.core.windows.net/images/logos/microsoft-imagine-academy.png">
              </picture>
            </div>
            <div class="logo p-2">
              <picture>
                <source srcset="https://pssstaticwebstorage.blob.core.windows.net/images/logos/microsoft-partner.webp" type="image/webp">
                <source srcset="https://pssstaticwebstorage.blob.core.windows.net/images/logos/microsoft-partner.png" type="image/png">
                <img alt="Microsoft Partner" class="card-img " src="https://pssstaticwebstorage.blob.core.windows.net/images/logos/microsoft-partner.png">
              </picture>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <footer class="container-fluid bg-black text-white py-4">
    <div class="container">
      <div class="row">
        <div class="col-md-6 text-left">
          <ul class="footer-li">
            <li><a routerLink="cookie-usage">Cookie Usage</a></li>
            <li><a routerLink="disclaimer">Disclaimer</a></li>
            <li><a routerLink="terms-conditions">Terms & Conditions</a></li>
            <li><a routerLink="privacy-notice">Privacy Policy</a></li>
          </ul>
        </div>
        <div class="col-md-6 text-right">
          Copyright @ {{year}} - Pioneering Solutions Studio (Pty) Ltd. All rights reserved | Designed & developed by
          <a target="_blank" class="text-primary" href="https://vertice.software/">VDS</a>.
        </div>
      </div>
    </div>
  </footer>
</div>
