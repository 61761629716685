import {Injectable} from '@angular/core';
import {Subject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class JsonLdService {

  schema = new Subject<object>();


  constructor() {
    this.schema.subscribe(val => console.log('JJJ', val));
  }

  updateJson(schema) {
    this.schema.next(schema);
  }
}
