import {Component, Input} from '@angular/core';
import {OwlOptions} from 'ngx-owl-carousel-o';

@Component({
  selector: 'app-logo-carousel',
  templateUrl: './logo-carousel.component.html',
  styleUrls: ['./logo-carousel.component.scss']
})
export class LogoCarouselComponent {

  @Input() key = 'corporate';

  slidesStore = {
    corporate: {
      heading: 'Our Corporate Clients',
      slides: [
        {
          id: '14', alt: 'Standard Bank'
        },
        {
          id: '01', alt: 'Old Mutual'
        },
        {
          id: '02', alt: 'Stanlib'
        },
        {
          id: '06', alt: 'GSK'
        },
        {
          id: '03', alt: 'PSG'
        },
        {
          id: '11', alt: 'Vertice'
        },
        {
          id: '15', alt: 'Nestle Nutrition'
        },


      ]
    },
    educational: {
      heading: 'Our Professional Education Partners',
      slides:

        [
          {
            id: '05', alt: 'FPD'
          },
          {
            id: '10', alt: 'UP Enterprises'
          },
          {
            id: '12', alt: 'HSA'
          },
          {
            id: '13', alt: 'Chart Learning'
          },
          {
            id: '07', alt: 'EBIX'
          }

        ]
    }
  };
  customOptions: OwlOptions = {
    loop: true,
    autoplay: true,
    mouseDrag: false,
    touchDrag: false,
    mergeFit: false,
    pullDrag: false,
    dots: false,
    nav: true,
    navSpeed: 700,
    navText: ['<i class="fa-chevron-left"></i>', '<i class="fa-chevron-right></i>"'],
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 2
      },
      740: {
        items: 3
      },
      940: {
        items: 4
      }
    },
  };

  constructor() {
  }
}
