import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {PageAboutUsComponent} from './page-about-us/page-about-us.component';
import {PageFunctionalityComponent} from './page-functionality/page-functionality.component';
import {PageServicesComponent} from './page-services/page-services.component';
import {PageIndustriesComponent} from './page-industries/page-industries.component';
import {PageCorporateComponent} from './page-corporate/page-corporate.component';
import {PageContactUsComponent} from './page-contact-us/page-contact-us.component';
import {PageHomeComponent} from './page-home/page-home.component';
import {PageCookieUsageComponent} from './page-cookie-usage/page-cookie-usage.component';
import {PageDisclaimerComponent} from './page-disclaimer/page-disclaimer.component';
import {PageTermsAndConditionsComponent} from './page-terms-and-conditions/page-terms-and-conditions.component';
import {PagePrivacyClausComponent} from './page-privacy-claus/page-privacy-claus.component';
import {PageAppUserAgreementComponent} from './page-app-user-agreement/page-app-user-agreement.component';
import {PageEducationComponent} from './page-education/page-education.component';
import {PageNotFoundComponent} from './page-not-found/page-not-found.component';
import {PageThankYouComponent} from './page-thank-you/page-thank-you.component';
import {PagePrivacyNoticeComponent} from './page-privacy-notice/page-privacy-notice.component';
import {PageCaptureMpcFormComponent} from './page-capture-mpc-form/page-capture-mpc-form.component';
import {PageFinanceThankYouComponent} from './page-finance-thank-you/page-finance-thank-you.component';
import { PageHiddenContactUsComponent } from './page-hidden-contact-us/page-hidden-contact-us.component';


const routes: Routes = [{path: 'about-us', component: PageAboutUsComponent},
  {path: 'functionality-and-features', component: PageFunctionalityComponent},
  {path: 'services', component: PageServicesComponent},
  {path: 'industries', component: PageIndustriesComponent},
  {path: 'corporate-clients', component: PageCorporateComponent},
  {path: 'professional-education-partners', component: PageEducationComponent},
  {path: 'contact-us', component: PageContactUsComponent},
  //{path: 'leadcapture', component: PageHiddenContactUsComponent},
  {path: 'cookie-usage', component: PageCookieUsageComponent},
  {path: 'disclaimer', component: PageDisclaimerComponent},
  {path: 'terms-conditions', component: PageTermsAndConditionsComponent},
  {path: 'privacy-claus', component: PagePrivacyClausComponent},
  {path: 'privacy-notice', component: PagePrivacyNoticeComponent},
  {path: 'thank-you', component: PageThankYouComponent},
  {path: 'capture-mpc-form', component: PageCaptureMpcFormComponent},
  {path: 'app-licence-agreement', component: PageAppUserAgreementComponent},
  {path: 'finance-thank-you', component: PageFinanceThankYouComponent},
  {path: '', component: PageHomeComponent},
  {path: '**', component: PageNotFoundComponent}];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    scrollPositionRestoration: 'enabled',
    anchorScrolling: 'enabled',
    scrollOffset: [0, 100]
  })],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
