<div class="banner-image educational">
  <div class="banner-text">
    <h1>TAILOR MADE FOR PROFESSIONAL <br> EDUCATION PARTNERS</h1>
    <p>Read more on how you can use TRISCOMS&reg; to offer Hybrid Education to your clients</p>
    <button class="btn btn-outline-light" routerLink="/functionality-and-features">LEARN MORE</button>
  </div>
</div>


<div class="container text-center pt-5">
  <h4>Hybrid Professional Education</h4>
  <p class="pt-3 text-center">Tailor made for Professional Education Partners</p>
</div>
<div class="container">
  <div class="mx-auto" style="max-width: 500px">
    <app-education-card></app-education-card>
  </div>
</div>

<div class="container-md pt-5">
  <table class="pricing-table mb-0 table table-borderless">
    <tr>
      <th class="align-bottom" style="width: 220px!important;">
        <div class="card solution-header border-round-top">Solution</div>
      </th>
      <th class="fixed-width">
        <div class="card product-header border-round-top shadow">
          <div class="card-header">
            <div class="pricing-header-icon"><img alt="Interactive Online Education"
                                                  src="https://pssstaticwebstorage.blob.core.windows.net/images/icons/education-2-icon.png"></div>
          </div>
          <div class="card-body">
            <div class="card-title">

              Education Partner <br>
              Software as a Service
            </div>
            <div class="card-subtitle font-italic">Sell to Our Professionals</div>
          </div>

        </div>

      </th>
      <th class="fixed-width">
        <div class="card product-header border-round-top shadow">
          <div class="card-header">
            <div class="pricing-header-icon"><img alt="Interactive Online Education"
                                                  src="https://pssstaticwebstorage.blob.core.windows.net/images/icons/education-2-icon.png"></div>
          </div>
          <div class="card-body">
            <div class="card-title">

              Education Partner<br>
              Bring Your Own Content
            </div>
            <div class="card-subtitle font-italic">Platform as a Service for Employers</div>
          </div>

        </div>

      </th>

    </tr>
  </table>
  <table class="pricing-table shadow mt-0 table table-borderless ">
    <tr>
      <td class="pricing-icon" style="width: 220px!important;">
        <div><img alt="On Demand Online Education"
                  src="https://pssstaticwebstorage.blob.core.windows.net/images/vectors/knowledge-center-vector.png"></div>
        <div class="mt-2">
          On Demand<br>
          Online Education
        </div>
      </td>
      <td class="align-middle fixed-width">
        <b>On Demand Online Education</b><br>
        Journals, Articles, Newsletters, Knowledge Updates or Short Courses

      </td>
      <td class="align-middle fixed-width">
        <b>On Demand Online Education</b><br>
        Journals, Articles, Newsletters, Knowledge Updates or Short Courses
      </td>

    </tr>
    <tr class="hr">
      <td>
        <hr>
      </td>
      <td>
        <hr>
      </td>
      <td>
        <hr>
      </td>

    </tr>
    <tr>
      <td class="pricing-icon">
        <div><img alt="Interactive Online Education"
                  src="https://pssstaticwebstorage.blob.core.windows.net/images/vectors/virtual-campus-vector.png"></div>
        <div class="mt-2">Interactive Online<br> Education</div>
      </td>
      <td class="align-middle">
        <b>Interactive Online Education</b><br>
        1 Virtual Training Room<br>
        10 hours of cloud recording.
      </td>
      <td class="align-middle">
        <b>Interactive Online Education</b><br>
        1 Virtual Training Room<br>
        10 hours of cloud recording.
      </td>

    </tr>
    <tr class="hr">
      <td>
        <hr>
      </td>
      <td>
        <hr>
      </td>
      <td>
        <hr>
      </td>
    </tr>
    <tr>
      <td class="pricing-icon">
        <div><img alt="Face to Face Training Sessions"
                  src="https://pssstaticwebstorage.blob.core.windows.net/images/vectors/event-central-vector.png"></div>
        <div class="mt-2"> Face to Face<br> Training Sessions
        </div>
      </td>
      <td class="align-middle"><b>Per Session</b></td>
      <td class="align-middle"><b>Per Session</b></td>
    </tr>
    <tr class="hr">
      <td>
        <hr>
      </td>
      <td>
        <hr>
      </td>
      <td>
        <hr>
      </td>
    </tr>
    <tr>
      <td class="pricing-icon">
        <div><img alt="Professional Development Portfolio"
                  src="https://pssstaticwebstorage.blob.core.windows.net/images/vectors/individual-vector.png"></div>
        <div class="mt-2">Professional<br>Development Portfolio</div>
      </td>
      <td class="align-middle">
        <div class="checkmark-icon"><img alt="Tick"
                                         src="https://pssstaticwebstorage.blob.core.windows.net/images/icons/checkmark.png"></div>
      </td>
      <td class="align-middle">
        <div class="checkmark-icon"><img alt="Tick"
                                         src="https://pssstaticwebstorage.blob.core.windows.net/images/icons/checkmark.png"></div>
      </td>

    </tr>
    <tr class="hr">
      <td>
        <hr>
      </td>
      <td>
        <hr>
      </td>
      <td>
        <hr>
      </td>
    </tr>
    <tr>
      <td class="pricing-icon">
        <div><img alt="Professional Reporting"
                  src="https://pssstaticwebstorage.blob.core.windows.net/images/vectors/lms-vector.png"></div>
        <div class="mt-2">Professional<br> Reporting</div>

      </td>
      <td class="align-middle">
        <div class="checkmark-icon"><img alt="Tick"
                                         src="https://pssstaticwebstorage.blob.core.windows.net/images/icons/checkmark.png"></div>
      </td>
      <td class="align-middle">
        <div class="checkmark-icon"><img alt="Tick"
                                         src="https://pssstaticwebstorage.blob.core.windows.net/images/icons/checkmark.png"></div>
      </td>

    </tr>
    <tr class="hr">

      <td>
        <hr>
      </td>
      <td>
        <hr>
      </td>
      <td>
        <hr>
      </td>
    </tr>
    <tr>
      <td class="pricing-icon-2">
        <div><img alt="Hosting, support and tech cost"
                  src="https://pssstaticwebstorage.blob.core.windows.net/images/icons/customer-support.png"></div>
        <div class="mt-2">Hosting, support<br> and tech cost</div>
      </td>
      <td class="align-middle">
        <div class="checkmark-icon"><img alt="Tick"
                                         src="https://pssstaticwebstorage.blob.core.windows.net/images/icons/checkmark.png"></div>
      </td>
      <td class="align-middle">
        <div class="checkmark-icon"><img alt="Tick"
                                         src="https://pssstaticwebstorage.blob.core.windows.net/images/icons/checkmark.png"></div>
      </td>

    </tr>
    <tr class="hr">

      <td>
        <hr>
      </td>
      <td>
        <hr>
      </td>
      <td>
        <hr>
      </td>
    </tr>
    <tr>
      <td class="pricing-icon-2">
        <div><img alt="Prices per Delegates"
                  src="https://pssstaticwebstorage.blob.core.windows.net/images/icons/prices-pd.png"></div>
        <div class="mt-2">Prices per Delegates</div>
      </td>
      <td class="text-left price-table-compact">
        <p>
          <b>On Demand Online Education</b><br>
          10% of sales price with a minimum of<br>
          R50.00 and a maximum of R350.00
        </p>
        <p>
          <b>Interactive Online Education</b><br>
          <b>Module Cost, Per Delegate, Per Course</b><br>
          10% of sales price with a minimum of<br>
          R50.00 and a maximum of R350.00
        </p>
        <p>
          <b>Face-to-Face Training Session</b><br>
          < Less then 25 Delegates R250.00 <br>
          > More then 25 Delegates R1000.00
        </p>

      </td>
      <td class="text-left price-table-compact">
        <p>
          <b>On Demand Online Education</b><br>
          10% of sales price with a minimum of<br>
          R50.00 and a maximum of R350.00<br>

          <b>Registered Qualifications</b><br>
          10% of sales price with a minimum of<br>
          R550.00 and a maximum of R10,050.00
        </p>
        <p>
          <b>Interactive Online Education</b><br>
          <b>Module Cost, Per Delegate, Per Course</b><br>
          10% of sales price with a minimum of<br>
          R50.00 and a maximum of R350.00
        </p>
        <p>
          <b>Face-to-Face Training Session</b><br>
          < Less then 25 Delegates R250.00 <br>
          > More then 25 Delegates R1000.00
        </p>


      </td>

    </tr>
    <tr>
      <td class="logo-row"></td>
      <td class="text-left pb-5"><img alt="" src="https://pssstaticwebstorage.blob.core.windows.net/images/logos/triscoms-logo-education.png"
                                      style="height: 45px;"></td>
      <td class="text-left pb-5"><img alt="" src="https://pssstaticwebstorage.blob.core.windows.net/images/logos/ed-online.png"
                                      style="height: 45px;"></td>
    </tr>
  </table>

</div>


<div class=" d-flex justify-content-center my-5 bg-white">

  <img alt="" class="m-3" src="https://pssstaticwebstorage.blob.core.windows.net/images/logos/azure-logo.png"
       style="height: 50px;">
  <img alt="" class="m-3" src="https://pssstaticwebstorage.blob.core.windows.net/images/logos/microsoft-partner.png"
       style="height: 50px;">
</div>
<div class="container p-5 my-5 border-box">
  <app-logo-carousel key="educational"></app-logo-carousel>
</div>
<div class="container-fluid goals-banner">
  <div class="text-center py-2">

    <img alt="" class="my-5" src="https://pssstaticwebstorage.blob.core.windows.net/images/logos/sustainable-development-logo.png"
         style="height: 70px; max-width: 90%">
    <div class="container pb-5">
      <h4>Detailed Analytics for your Sustainability Report</h4>
      <h4 class="mb-0"><a class="contact-us-link" routerLink="/contact-us">CONTACT US</a></h4>

    </div>
  </div>
</div>
