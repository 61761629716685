<div class="banner-image">

  <video autoplay height="100%" loop muted poster="https://pssstaticwebstorage.blob.core.windows.net/images/banners/industries-banner.jpg" width="100%">
    <source src="https://pssstaticwebstorage.blob.core.windows.net/images/banners/industries-banner.webm" type="video/webm">
    <source src="https://pssstaticwebstorage.blob.core.windows.net/images/banners/industries-banner.mp4" type="video/mp4">
    <p class="warning">Your browser does not support HTML5 video.</p>
  </video>

  <div class="banner-text">
    <h1>OUR ED.ONLINE INDUSTRIES</h1>
    <p>We develop, implement, and maintain online Professional compliance and Education Platforms for various
      industries. </p>
  </div>
</div>
<div class="container pt-5 text-center">

  <h2 class="text-center">OUR EDONLINE INDUSTRIES</h2>
  <p class="pt-3 text-center">We develop, implement and maintain online Professional compliance and Education Platforms
    for various
    industries.</p>

  <p class="pt-3 text-center">
    We use TRISCOMS&reg; to support Professionals from many industries. These industry-specific deployments of TRISCOMS&reg;
    is to provide Professionals with Continuing Professional Development (CPD) content that has been expertly sourced
    for their educational needs. ED.online takes the pain out of finding educational content that is relevant to the
    South African Professional. All our material is CPD-accredited and expertly sourced from our leading Professional
    Education Partners, which include organisations like Enterprises at the University of Pretoria and Foundation for
    Professional Development.
  </p>
  <p class="pt-3 text-center">
    Each industry platform is also aligned to the Regulatory CPD requirements of the respective industry (where
    applicable) and allows Professionals to export their Professional Development Portfolios for submission to their
    respective councils or associations.

  </p>
  <h2 class="text-center">Industry specific online training platform</h2>
  <p class="pt-3 text-center">
    Supporting Professional Education from A to Z.
  </p>


</div>
<!--<div class="container">-->
<!--  <div class="d-flex flex-wrap justify-content-center">-->

<!--    <div (click)="openUrl(tile.url)" *ngFor="let tile of tiles2" [ngClass]="tile.rowType"-->
<!--         [ngStyle]="{'background-image': imageUrl(tile)}"-->
<!--         class="flex-tile rounded-0"-->
<!--    >-->

<!--      <img [alt]="tile.logoAlt" [class]="tile.logoClass"-->
<!--           [src]="logoUrl(tile)"-->
<!--           class="logo-image shadow-lg">-->
<!--      <div-->
<!--        [ngStyle]="{'background-image': barUrl(tile)}"-->
<!--        class="text-white banner-overlay font-weight-bold">-->
<!--        <div class="content">{{tile.bannerText}}</div>-->
<!--      </div>-->
<!--    </div>-->
<!--  </div>-->
<!--</div>-->

<div class="container">
  <div class="d-flex flex-wrap justify-content-center">

    <div (click)="openUrl(tile.url)" (deferLoad)="tile.show = true" *ngFor="let tile of tiles2"
         [ngClass]="tile.rowType"
         [ngStyle]="{'background-image': imageUrl(tile)}"
         class="flex-tile rounded-0"
    >
      <ng-container *ngIf="tile.show">
        <img [alt]="tile.logoAlt" [class]="tile.logoClass"
             [src]="logoUrl(tile)"
             class="logo-image shadow-lg">
      </ng-container>
      <div
        [ngStyle]="{'background-image': barUrl(tile)}"
        class="text-white banner-overlay font-weight-bold">
        <div class="content">{{tile.bannerText}}</div>
      </div>
    </div>
  </div>
</div>

<div class="container">

  <h2 class="text-center pt-5">ED.online is geared to accommodate the sponsorship of education to targeted Professional
    audiences via scholarship by 3rd party organisations or Corporate Sponsors to promote learning within specific
    fields or specialities.</h2>

  <p class="pt-3 text-left">
    Corporate Sponsors invest in the education of Professionals in their respective industries by providing
    Scholarships:
  </p>
  <ul>
    <li class="pb-4">
      To enhance the Professionals’ understanding of the Corporate Sponsor’s products, where the sale of the Sponsor’s
      products is based on the intervention of a Professional. (These courses are generally not CPD accredited because
      the educational subject matter is developed by the Sponsor (BYOC) and is Sponsor product specific) and
    </li>
    <li>
      Market their brands to Professionals through education. In this case third party content is generally sourced from
      PSS Professional Education Partners and the cost of completion is paid for by the Sponsor. Through investing in
      the education of Professionals through providing Scholarships to accredited content, Sponsors develop respected
      and sustainable relationships with the Professionals that serve their industry. (These courses are generally CPD
      accredited because they are developed by an independent Professional Education Partner and is not Sponsor product
      specific).
    </li>
  </ul>

  <div class="pt-3 text-center w-100">
    <div class="w-50 text-right" style="display: inline-block">
      <button class="btn btn-outline-dark rounded-0 m-2" routerLink="/services">LEARN MORE</button>

    </div>
    <div class="w-50 text-left" style="display: inline-block">
      <button class="btn btn-dark rounded-0 m-2" routerLink="/contact-us">REQUEST A DEMO</button>
    </div>
  </div>
</div>
