<div class="banner-image">
  <div class="banner-text">
    <h1>Standard Bank Application for Financing</h1>
    <img alt="Standard Bank Logo" src="https://pssstaticwebstorage.blob.core.windows.net/images/logos/standard_bank_new.png" class="logo" style="width:30%">
    <img alt="Standard Bank Logo" src="https://pssstaticwebstorage.blob.core.windows.net/images/logos/MPC_Store.png" class="Standard bank logo" style="width:15%">
  </div>
</div>
<div class="container pt-5 text-center">

  <div class="row">
    <div class="col-12 col-md-2"></div>
    <div class="col-12 col-md-8 py-2">
      <form action='' [formGroup]="uploadForm" method="post" id="uploadForm">
        <fieldset>
          <ol class="d-flex flex-wrap pl-0">
            <div class="col-md-12 d-flex align-items-end mt-2">
              <label for="fullName">Name</label>
              <li class="form-group white-form col-md-12 px-0">
                <b>Name:</b> <span style="color:red;">*</span>
                <input class="form-control" required="true" formControlName="fullName" id="fullName" name="fullName"  type="text" />
                <div style="clear:both;"></div>
              </li>
            </div>

            <div class="col-md-12 d-flex align-items-end mt-2">
              <li class="form-group white-form col-md-12 px-0">
                <label for="surname">Surname:</label>
                <b>Surname:</b> <span style="color:red;">*</span>
                <input class="form-control" required="true" formControlName="surname" id="surname" name="surname" type="text" />
                <div style="clear:both;"></div>
              </li>
            </div>

            <div class="col-md-12 d-flex align-items-end mt-2">
              <li class="form-group white-form col-md-12 px-0">
                <label for="contact">Your contact number</label>
                <b>Contact Number:</b>
                <input class="form-control" formControlName="contact" id="contact" name="contact" type="text" />
                <div style="clear:both;"></div>
              </li>
            </div>

            <div class="col-md-12 d-flex align-items-end mt-2">
              <li class="form-group white-form col-md-12 px-0">
                <label for="email">Your email address</label>
                <b>E-mail Address:</b> <span style="color:red;">*</span>
                <input class="form-control" required="true" formControlName="email" id="email" name="email" email="true"  type="text" />
                <div style="clear:both;"></div>
              </li>
            </div>

            <div class="col-md-12 d-flex align-items-end mt-2">
              <li class="form-group white-form col-md-12 px-0">
                <label for="suburb">Suburb</label>
                <b>Suburb:</b>
                <input class="form-control" formControlName="suburb" id="suburb" name="suburb"  type="text" />
                <div style="clear:both;"></div>
              </li>
            </div>

            <div class="col-md-12 d-flex align-items-end mt-2">
              <li class="form-group white-form col-md-12 px-0">
                <label for="province">Province</label>
                <b>Province:</b>
                <select class="form-control" formControlName="province" name="province" id="province">
                  <option value=""></option>
                  <option value="Gauteng">Gauteng</option>
                  <option value="Limpopo">Limpopo</option>
                  <option value="Mpumalanga">Mpumalanga</option>
                  <option value="Free State">Free State</option>
                  <option value="North West">North West</option>
                  <option value="Kwazulu Natal">Kwazulu Natal</option>
                  <option value="Eastern Cape">Eastern Cape</option>
                  <option value="Western Cape">Western Cape</option>
                  <option value="Northern Cape">Northern Cape</option>
                </select>
                <div style="clear:both;"></div>
              </li>
            </div>

            <div class="col-md-12 d-flex align-items-end mt-2">
                <b>When will it be most convenient for Standard Bank to contact you to discuss the financing application?</b>
            </div>

            <div class="col-md-1 d-flex align-items-end mt-2">
              <li class="form-group white-form col-md-12 px-0">
                Between
                <div style="clear:both;"></div>
              </li>
            </div>

            <div class="col-md-5 d-flex align-items-end mt-2">
              <li class="form-group white-form col-md-12 px-0">
                <label for="between"></label>
                <input class="form-control" formControlName="between" id="between" name="between"  type="time" />
                <div style="clear:both;"></div>
              </li>
            </div>

            <div class="col-md-1 d-flex align-items-end mt-2">
              <li class="form-group white-form col-md-12 px-0">
                and
                <div style="clear:both;"></div>
              </li>
            </div>

            <div class="col-md-5 d-flex align-items-end mt-2">
              <li class="form-group white-form col-md-12 px-0">
                <label for="andDate"></label>
                <input class="form-control" formControlName="andDate" id="andDate" name="andDate"  type="time" />
                <div style="clear:both;"></div>
              </li>
            </div>

            <div class="col-md-2 d-flex align-items-end mt-2">
              <li class="form-group white-form col-md-12 px-0">
                <label for="weekdays">Weekdays:</label>
                On: Weekdays:
                <div style="clear:both;"></div>
              </li>
            </div>

            <div class="col-md-4 d-flex align-items-end mt-2">
              <li class="form-group white-form col-md-12 px-0">
                <input class="form-control" formControlName="weekdays" id="weekdays" name="weekdays"  type="checkbox" style="margin-left: -113px;"/>
                <div style="clear:both;"></div>
              </li>
            </div>

            <div class="col-md-1 d-flex align-items-end mt-2">
              <li class="form-group white-form col-md-12 px-0">
                <label for="weekend">Weekend:</label>
                Weekend:
                <div style="clear:both;"></div>
              </li>
            </div>

            <div class="col-md-5 d-flex align-items-end mt-2">
              <li class="form-group white-form col-md-12 px-0">
                <input class="form-control" formControlName="weekend" id="weekend" name="weekend"  type="checkbox" style="margin-left: -113px;"/>
                <div style="clear:both;"></div>
              </li>
            </div>

            <div class="col-md-3 d-flex align-items-end mt-2">
              <li class="form-group white-form col-md-12 px-0">
                Preferred Contact Method:
                <div style="clear:both;"></div>
              </li>
            </div>

            <div class="col-md-2 d-flex align-items-end mt-2">
              <li class="form-group white-form col-md-12 px-0">
                Email:
                <div style="clear:both;"></div>
              </li>
            </div>

            <div class="col-md-2 d-flex align-items-end mt-2">
              <li class="form-group white-form col-md-12 px-0">
                <label for="method_email">Email:</label>
                <input class="form-control" formControlName="method_email" id="method_email" name="method_email"  type="checkbox" style="margin-left:-106px;"/>
                <div style="clear:both;"></div>
              </li>
            </div>

            <div class="col-md-2 d-flex align-items-end mt-2">
              <li class="form-group white-form col-md-12 px-0">
                <label for="telephone">Telephone:</label>
               Telephone:
                <div style="clear:both;"></div>
              </li>
            </div>

            <div class="col-md-3 d-flex align-items-end mt-2">
              <li class="form-group white-form col-md-12 px-0">
                <input class="form-control" formControlName="telephone" id="telephone" name="telephone"  type="checkbox" style="margin-left:-103px;"/>
                <div style="clear:both;"></div>
              </li>
            </div>

            <br/>
            <div class="col-md-12 d-flex align-items-end mt-2">
              <b>How the process will work:</b>
            </div>

            <div class="col-md-12 d-flex align-items-end mt-2">
              <ol>
                <li>1.	Once you have submitted your application for financing on the MPC Online Store product, we will submit your application to a Standard Bank consultant. </li>
                <li>2.	The Standard Bank consultant will contact you to discuss the requirements, terms and conditions for your financing application.</li>
                <li>3.	Once approved for financing, Standard Bank will confirm that you have been approved for financing and we will complete the order for processing and delivery.</li>
              </ol>
            </div>
            <br/>

            <div class="col-md-12 d-flex align-items-end mt-2">
              <div class="form-group white-form col-md-2 px-0">
                <label for="disclaimer"></label>
                <input class="form-control" required="true" formControlName="disclaimer" id="disclaimer" name="disclaimer"  type="checkbox" style="margin-bottom:101px;"/>
                <div style="clear:both;"></div>
              </div>


              <div class="form-group white-form col-md-10 px-0">
                <p>By signing up for this financing application, I understand, and consent to the MPC Online Store sharing my personal information with Standard Bank and such information being processed by the MPC Online Store and Standard Bank and agree that Standard Bank may contact me in relation to the application and requirements needed for financing. </p>
                <div style="clear:both;"></div>
              </div>

            </div>

            <div class="col-md-12 d-flex align-items-end mt-2">
              <li class="preloader not_xhr">
                <button type="button" (click)="send()"  [disabled]="uploadForm.invalid" class="btn btn-dark rounded-0 m-2" id="submit-button">Submit Application</button>
              </li>
            </div>
          </ol>
        </fieldset>


      </form>

    </div>
    <div class="col-12 col-md-2"></div>
  </div>
</div>
<script src='https://leads-capturer.futuresimple.com/static/js/jquery-1.10.2.min.js' ></script>
<script src='https://leads-capturer.futuresimple.com/static/js/jquery.placeholder.js' ></script>
<script src='https://leads-capturer.futuresimple.com/static/chosen/chosen.jquery.min.js' ></script>
<script src='https://leads-capturer.futuresimple.com/static/js/form_embedded.min.js' ></script>
<script src='https://leads-capturer.futuresimple.com/static/js/report_size.min.js' ></script>
<script src='https://leads-capturer.futuresimple.com/static/datetimepicker/jquery.datetimepicker.min.js' ></script>


