<div class="container  my-5">
  <div class="row">
    <div class="col-md-12">
      <h3 class="page-heading text-center padding">Privacy Notice</h3>

      <p class="justify">This privacy notice is part of your agreement with us. </p>


      <p class="justify">During our interactions, you share personal information with Pioneering Solutions Studio (Pty) Ltd (PSS), registration number 2008/024971/07. </p>

      <p class="justify">This notice tells you what to expect when we collect and use your information. It is part of our agreement with you, and we may need to update it occasionally, but we will inform you when we do. You should read this notice along with the terms and conditions that apply to the products and services you use. </p>

      <p class="justify">If you have any questions, please contact us at support@ps.studio.</p>

      <p class="justify">We collect your information when you interact with us.</p>

      <h3>When you create an online profile </h3>

      <p class="justify">We collect your information when you create an online profile. Some of the fields are mandatory. If you do not complete all the required fields, you will not be able to create a profile. We will prompt you if this is the case. </p>

      <p class="justify">It includes your: </p>
      <ul>
        <li>name</li>
        <li>mobile number</li>
        <li>Email address</li>
        <li>communication preferences</li>
        <li>ID number </li>
        <li>council numbers </li>
        <li>billing address </li>
        <li>postal address </li>
        <li>gender </li>
        <li>ethnicity </li>
        <li>date of birth </li>
        <li>previous academic achievements </li>
        <li>employer related information </li>
    </ul>

    <p class="justify">We use this information to: </p>
    <ul>
      <li>create your profile; </li>
      <li>manage CPD services;  </li>
      <li>provide and accredit training;</li>
      <li>track event attendance tracking; </li>
      <li>submit data to legislative entities; </li>
      <li>send marketing information from our group of companies, if you asked us to. </li>
    </ul>

    <h3>When you contact us, we collect information from your message </h3>
    <p class="justify">When you contact us by social media, email, telephone, or online chat with a query, complaint, or request, we collect the information included in your message. We use the information we collect to reply to, investigate, and resolve your query, complaint, or request. </p>

    <h3>When you take part in a competition </h3>
    <p class="justify">We will use the information you provided on the entry form to administer the competition and to communicate with you about the competition. </p>
      <ul>
        <li>If you win a prize, we will ask for your address to deliver the prize </li>
        <li>We use your information to send you our monthly newsletter if you have requested. </li>
      </ul>

      <p class="justify">We will ask you if you want to receive the newsletter and if you agree, it is important that you know you can unsubscribe at any time by following the unsubscribe link at the bottom of the email or by contacting us. </p>

      <h3>When you are a member of our loyalty programme </h3>
      <p class="justify">If you are a member of our loyalty programme, we have to process information about your shopping behaviour and voucher use, to ensure that you receive your rewards. </p>

      <h3>When you take part in a survey </h3>
      <p class="justify">You may choose to complete your name and contact details when you take part in one of our surveys, although you always have the option to take part anonymously. We may use this information to give you feedback about the survey. </p>



      <h3>What about children's information? </h3>

      <p class="justify">You have to be older than 18 to use our services or products. When you order our products, we will ask you to confirm that you are older than 18. </p>
      <p class="justify">We do not knowingly collect the personal information of children without the consent of a parent or guardian. </p>
      <p class="justify">If we tailor our products or services for children, we will ask for consent from the child's parent or guardian. </p>



      <h3>We share your information with trusted service providers</h3>
      <p class="justify">We use suppliers and service providers who we trust to assist us in providing our products to you. They have agreed to keep your information secure and confidential and to only use it for the purposes for which we have sent it to them. </p>



      <p class="justify">Here are some of the instances when we share your information: </p>

      <ul>
        <li>to store information </li>
        <li>to manage our social media interactions </li>
        <li>to deliver our newsletter </li>
        <li>to help monitor the effectiveness of our promotions and advertising </li>
        <li>to help us manage our business, for instance, accountants and professional advisors. </li>
        <li>to provide IT services </li>
        <li>to maintain our website and applications </li>
        <li>to accredit training </li>
        <li>to provide council and others with training records and CPD status. </li>
      </ul>

      <p class="justify">Sometimes we will be required by law to share your information. For instance, we may be required to share your information with the South African Fraud Prevention Services. </p>



      <h3>We send your information to other countries </h3>
      <p class="justify">Some of the service providers we use may be located in other countries; for instance, our cloud storage service. These countries may not have the same levels of protection of personal information as South Africa. If this is the case, we require that they undertake to protect the personal data of our customers to the same level that we do. </p>

      <h3>We do not keep your information longer than we need to </h3>
      <p class="justify">We will not retain your information for longer than we need to unless we are legally required to do so. </p>



      <h3>We have taken reasonable steps to minimise the impact of a breach</h3>

      <p class="justify">We have implemented reasonable security measures based on the sensitivity of the information we hold. These measures are in place to protect the data from being disclosed, loss, misuse, unauthorised access, and from being altered or destroyed. </p>

      <p class="justify">We regularly monitor our systems for possible vulnerabilities and attacks. Still, no system is perfect, and we cannot guarantee that we will never experience a breach of any physical, technical, or managerial safeguards. If something should happen, we have taken steps to minimise the threat to your privacy. We will let you know of any breaches that affect your personal information and inform you how you can help minimise the impact.</p>

      <p class="justify">You also have a role to play in keeping your information secure. For example, you should never share personal information with us in an email, because while our servers are protected, it is still possible for that email to be intercepted. Instead, contact us at +27121117000. </p>



      <h3>You have the right to be informed about the personal information we have, and what we do with it. </h3>
      <p class="justify">You have the right to know what kind of personal information we have about you, to correct it, and to opt-out of marketing. </p>

      <p class="justify">You have the right to </p>
        <ul>
          <li>ask us what we know about you; </li>
          <li>ask what information was sent to our suppliers, service providers, or any other third party; </li>
          <li>ask us to update, correct, or delete any out-of-date or incorrect personal information we have about you; </li>
          <li>unsubscribe from any direct marketing communications we may send you; and </li>
          <li>object to the processing of your personal information.</li>
        </ul>
      <p class="justify">You can request access to the information we have about you or correct your personal information by contacting our deputy information officer at info@ps.studio. </p>
      <p class="justify">It can take us up to 21 days to respond to your request because there are procedures that we need to follow. In some instances, we may require proof of your identity, and sometimes changes to your information may be subject to additional requirements such as valid proof of residence. </p>


    </div>
  </div>
</div>
