<div class="container  my-5">
  <div class="row">
    <div class="col-md-12">
      <h3 class="page-heading text-center padding">Cookie Policy</h3>

      <p class="justify">This is the Cookie Policy for Pioneering Solutions Studio Public Website, accessible from
        https://www.ps.studio</p>

      <h3>What Are Cookies</h3>

      <p class="justify">As is common practice with almost all professional websites this site uses cookies, which are
        tiny files that are downloaded to your computer, to improve your experience. This page describes what
        information they gather, how we use it and why we sometimes need to store these cookies. We will also share how
        you can prevent these cookies from being stored however this may downgrade or 'break' certain elements of the
        sites functionality.</p>
      <p class="justify">For more general information on cookies see the Wikipedia article on HTTP Cookies.</p>

      <h3>How We Use Cookies</h3>
      <p class="justify">We use cookies for a variety of reasons detailed below. Unfortunately in most cases there are
        no industry standard options for disabling cookies without completely disabling the functionality and features
        they add to this site. It is recommended that you leave on all cookies if you are not sure whether you need them
        or not in case they are used to provide a service that you use.</p>

      <h3>Disabling Cookies</h3>
      <p class="justify">You can prevent the setting of cookies by adjusting the settings on your browser (see your
        browser Help for how to do this). Be aware that disabling cookies will affect the functionality of this and many
        other websites that you visit. Disabling cookies will usually result in also disabling certain functionality and
        features of the this site. Therefore it is recommended that you do not disable cookies.</p>

      <h3>The Cookies We Set</h3>
      <ul>
        <li>Email newsletters related cookies <br/>
          This site offers newsletter or email subscription services and cookies may be used to remember if you are
          already registered and whether to show certain notifications which might only be valid to
          subscribed/unsubscribed users.
        </li>
        <li>Forms related cookies <br/>
          When you submit data to through a form such as those found on contact pages or comment forms cookies may be
          set to remember your user details for future correspondence.
        </li>
        <li>Site preferences cookies <br/>
          In order to provide you with a great experience on this site we provide the functionality to set your
          preferences for how this site runs when you use it. In order to remember your preferences we need to set
          cookies so that this information can be called whenever you interact with a page is affected by your
          preferences.
        </li>
      </ul>

      <h3>Third Party Cookies</h3>
      <p class="justify">In some special cases we also use cookies provided by trusted third parties. The following
        section details which third party cookies you might encounter through this site.</p>
      <ul>
        <li>This site uses Google Analytics which is one of the most widespread and trusted analytics solution on the
          web for helping us to understand how you use the site and ways that we can improve your experience. These
          cookies may track things such as how long you spend on the site and the pages that you visit so we can
          continue to produce engaging content. <br/>
          For more information on Google Analytics cookies, see the official Google Analytics page.
        </li>
        <li>From time to time we test new features and make subtle changes to the way that the site is delivered. When
          we are still testing new features these cookies may be used to ensure that you receive a consistent experience
          whilst on the site whilst ensuring we understand which optimisations our users appreciate the most.
        </li>
        <li>We also use social media buttons and/or plugins on this site that allow you to connect with your social
          network in various ways. For these to work the following social media sites including; Facebook and LinkedIn
          will set cookies through our site which may be used to enhance your profile on their site or contribute to the
          data they hold for various purposes outlined in their respective privacy policies.
        </li>
      </ul>

      <h3>More Information</h3>
      <p class="justify">Hopefully that has clarified things for you and as was previously mentioned if there is
        something that you aren't sure whether you need or not it's usually safer to leave cookies enabled in case it
        does interact with one of the features you use on our site. </p>
      <p class="justify">However if you are still looking for more information then you can contact us through one of
        our preferred contact methods:</p>
      <ul>
        <li>By visiting this link: https://support.ps.studio/</li>
      </ul>

    </div>
  </div>
</div>
