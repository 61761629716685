<div class="text-center mx-auto no-gutters  container-xl">

  <div class="col-md-12">
    <ul class="timeline timeline-centered">
      <li class="timeline-item period period-marker">
        <div class="timeline-marker"></div>
        <div class="timeline-info"></div>
        <div class="timeline-content">
        </div>

      </li>
      <ng-container *ngFor="let item of items; index as i">
        <app-timeline-item [index]="i" [item]="item"></app-timeline-item>
      </ng-container>

    </ul>
  </div>
</div>
