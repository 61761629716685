import {Component} from '@angular/core';
import {IndustryTile} from '../industry-tile';
import {Meta, Title} from '@angular/platform-browser';
import {JsonLdService} from '../shared/json-ld.service';

@Component({
  selector: 'app-page-industries',
  templateUrl: './page-industries.component.html',
  styleUrls: ['./page-industries.component.scss']
})
export class PageIndustriesComponent {

  tiles2: IndustryTile[] = [{


    bannerText: 'Built Professionals',
    url: 'www.builted.online',
    imageKey: 'BuiltED',
    logoAlt: 'Built EDOnline',
    rowType: 'even-row',
    logoClass: '',
    show: false
  }
  // , 
  // {
  //   bannerText: 'Education Professionals',
  //   url: 'www.edued.online',
  //   imageKey: 'EduED',
  //   logoAlt: 'Education EDOnline',
  //   rowType: 'even-row',
  //   logoClass: '',
  //   show: true
  // }
  // , {
  //   bannerText: 'Event Professionals',
  //   url: 'www.eventsed.online',
  //   imageKey: 'EventsED',
  //   logoAlt: 'Event EDOnline',
  //   rowType: 'even-row',
  //   logoClass: '',
  //   show: false

  // }
  , {
    bannerText: 'Financial Professionals',
    url: 'www.fined.online',
    imageKey: 'FinED',
    logoAlt: 'Financial EDOnline',
    rowType: 'even-row',
    logoClass: '',
    show: false
  }, {

    bannerText: 'IT Professionals',
    url: 'www.icted.online',
    imageKey: 'ICTED',
    logoAlt: 'IT EDOnline',
    rowType: 'odd-row',
    logoClass: '',
    show: false
  }, {
    bannerText: 'Market Professionals',
    url: 'www.marked.online',
    imageKey: 'MarkED',
    logoAlt: 'Market EDOnline',
    rowType: 'odd-row',
    logoClass: '',
    show: false

  }, 
  // {
  //   bannerText: 'Mining Professionals',
  //   url: '',
  //   imageKey: 'MineED',
  //   logoAlt: 'Mining EDOnline',
  //   rowType: 'odd-row',
  //   logoClass: '',
  //   show: false
  // },
   {
    bannerText: 'Natural Science and Farming Professionals',
    url: 'www.nated.online',
    imageKey: 'NatED',
    logoAlt: 'Natural EDOnline',
    rowType: 'even-row',
    logoClass: '',
    show: false
  },
  //  {
  //   bannerText: 'Pharmaceutical Professionals',
  //   url: 'www.pharmed.online/',
  //   imageKey: 'PharmED',
  //   logoAlt: 'Pharmaceutical EDOnline',
  //   rowType: 'even-row',
  //   logoClass: '',
  //   show: false
  // },
  //  {
  //   bannerText: 'Actively Retired Professionals',
  //   url: 'www.activelyretired.online',
  //   imageKey: 'ActRetED',
  //   logoAlt: 'Actively EDOnline',
  //   rowType: 'even-row',
  //   logoClass: '',
  //   show: false
  // },
  //  {
  //   bannerText: 'Sports Professionals',
  //   url: 'www.sportsed.online',
  //   imageKey: 'SportsED',
  //   logoAlt: 'Sports EDOnline',
  //   rowType: 'even-row',
  //   logoClass: '',
  //   show: false

  // }, 
  {

    bannerText: 'Veterinary Professionals',
    url: 'www.veted.online',
    imageKey: 'VetED',
    logoAlt: 'Vet EDOnline',
    rowType: 'odd-row',
    logoClass: '',
    show: false
  },
  //  {
  //   bannerText: 'Wellbeing Enthusiasts',
  //   url: 'py-sm-0 py-lg-2',
  //   imageKey: 'WellnessED',
  //   logoAlt: 'Wellbeing EDOnline',
  //   rowType: 'odd-row',
  //   logoClass: '',
  //   show: false
  // },
  //  {
  //   bannerText: 'Healthcare Professionals',
  //   url: 'www.mpc.healthcare',
  //   imageKey: 'MPC',
  //   logoAlt: 'Healthcare PCOnline',
  //   rowType: 'odd-row',
  //   logoClass: 'mpc-logo',
  //   show: false

  // }
];

  constructor(private titleService: Title, private metaService: Meta, private jsonLdService: JsonLdService) {

    titleService.setTitle('Educational platforms supporting industries Froma A to Z');

     jsonLdService.updateJson({
       '@context': 'http://schema.org',
       '@type': 'WebSite',
      name: 'Pioneering Solutions Studio (PSS)',
      url: 'https://www.ps.studio/industries'
     });
    
    metaService.addTags([
      {name: 'keywords', content: 'Professional compliance, Education Platforms'},
      {name: 'description', content: 'We develop, implement and maintain online Professional compliance and Education Platforms for various industries from Medical to Mining we Support them all'},
      {name: 'robots', content: 'index, follow'}
    ]);
  }

  barUrl(tile) {
    // @ts-ignore
    const ext = window.Modernizr.webp ? 'webp' : 'png';
    return tile ? `url("https://pssstaticwebstorage.blob.core.windows.net/images/industries/${tile.imageKey} Bar.${ext}")` : '';
  }

  imageUrl(tile) {
    // @ts-ignore
    const ext = window.Modernizr.webp ? 'webp' : 'png';
    return tile ? `url("https://pssstaticwebstorage.blob.core.windows.net/images/industries/${tile.imageKey} Image.${ext}")` : '';
  }

  logoUrl(tile) {
    // @ts-ignore
    const ext = window.Modernizr.webp ? 'webp' : 'png';
    return tile ? `https://pssstaticwebstorage.blob.core.windows.net/images/industries/${tile.imageKey} Logo.${ext}` : '';
  }

  openUrl(url: string) {
    window.open('https://' + url);
  }
}










