<nav class="navbar fixed-top navbar-expand-lg navbar-dark header">
  <div class="container-xl  ">
    <a class=" navbar-brand" routerLink="">
      <picture>
        <source srcset="https://pssstaticwebstorage.blob.core.windows.net/images/logos/pss-logo-small.webp" type="image/webp">
        <source srcset="https://pssstaticwebstorage.blob.core.windows.net/images/logos/pss-logo-small.png" type="image/png">
        <img alt="PSS Logo" class="logo" src="https://pssstaticwebstorage.blob.core.windows.net/images/logos/pss-logo-small.png">
      </picture>
    </a>
    <!--    <div style="width: 200px"></div>-->


    <button aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation" class="navbar-toggler"
            data-target="#navbarNav"
            data-toggle="collapse" type="button">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse ml-auto" id="navbarNav">
      <ul class="navbar-nav  ml-auto w-100">
        <li class="nav-item" data-target=".navbar-collapse.show" data-toggle="collapse" routerLinkActive="active"><a
          class="nav-link" href="#" routerLink="about-us">About Us</a>
        </li>
        <li class="nav-item" data-target=".navbar-collapse.show" data-toggle="collapse" routerLinkActive="active"><a
          class="nav-link" href="#"
          routerLink="functionality-and-features">Functionality
          &amp; Features </a></li>
        <li class="nav-item" data-target=".navbar-collapse.show" data-toggle="collapse" routerLinkActive="active"><a
          class="nav-link" href="#" routerLink="services">Services</a>
        </li>
        <li class="nav-item" data-target=".navbar-collapse.show" data-toggle="collapse" routerLinkActive="active"><a
          class="nav-link" href="#"
          routerLink="industries">Industries</a></li>
        <li class="nav-item" data-target=".navbar-collapse.show" data-toggle="collapse" routerLinkActive="active"><a
          class="nav-link" href="#"
          routerLink="corporate-clients">Corporate
          Clients</a></li>
        <li class="nav-item" data-target=".navbar-collapse.show" data-toggle="collapse" routerLinkActive="active"><a
          class="nav-link"
          href="#"
          routerLink="professional-education-partners">Professional
          Education Partners</a></li>
        <li class="nav-item" data-target=".navbar-collapse.show" data-toggle="collapse" routerLinkActive="active"><a
          class="nav-link" href="#" routerLink="contact-us">Contact
          Us</a></li>

        <li class="nav-item">
          <a class="nav-link" title="Visit us at Facebook" rel="noreferrer noopener" href="https://www.facebook.com/PioneeringSolutionsStudio/" target="_blank">


            <fa-icon  [icon]="faFacebookF"></fa-icon>
          </a>
        </li>

        <li class="nav-item">
          <a class="nav-link" title="Visit us at LinkedIn" rel="noreferrer noopener" href="https://www.linkedin.com/company/pioneering-solutions-studio" target="_blank">


            <fa-icon  [icon]="faLinkedIn"></fa-icon>
          </a>
        </li>

      </ul>
    </div>
  </div>
</nav>
<div class="buffer"></div>
