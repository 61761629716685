import {Component} from '@angular/core';
import {Meta, Title} from '@angular/platform-browser';
import {JsonLdService} from '../shared/json-ld.service';

@Component({
  selector: 'app-page-services',
  templateUrl: './page-services.component.html',
  styleUrls: ['./page-services.component.scss']
})
export class PageServicesComponent {

  constructor(private titleService: Title, private metaService: Meta, private jsonLdService: JsonLdService) {

    titleService.setTitle('The A to Z of Professional Education');

     jsonLdService.updateJson({
       '@context': 'http://schema.org',
       '@type': 'WebSite',
       name: 'Pioneering Solutions Studio (PSS)',
       url: 'https://www.ps.studio/services'
     });
    
     metaService.addTags([
       {name: 'keywords', content: 'CPD Accreditation, Instructional Design'},
       {name: 'description', content: 'Not only do we provide  TRISCOMS® & all its functionality but also services like Instructional Design, CPD Accreditation of learning material and so much more'},
       {name: 'robots', content: 'index, follow'}
     ]);
  }

}
