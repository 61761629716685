import {Component, ElementRef, HostListener, Inject, Input, OnInit, ViewChild} from '@angular/core';
import {TimelineItem} from '../timeline-item';
import {DOCUMENT} from '@angular/common';
import {WINDOW} from '../window.service';
import * as move from 'move-js';

@Component({
  selector: 'app-timeline-item',
  templateUrl: './timeline-item.component.html',
  styleUrls: ['./timeline-item.component.scss']
})
export class TimelineItemComponent implements OnInit {

  @ViewChild('pictureView') pictureView;
  @ViewChild('contentView') contentView;

  @Input() item: TimelineItem;
  @Input() index;
  factor = 1;
  private anim = false;

  constructor(private el: ElementRef, @Inject(DOCUMENT) private document: Document,
              @Inject(WINDOW) private window: Window) {
  }

  ngOnInit(): void {
    this.factor = this.index % 2 === 0 ? -1 : 1;
  }

  @HostListener('window:resize', [])
  onWindowResize() {
    if (this.anim) {
      return false;
    }
    this.anim = true;
    setTimeout(() => {
      this.process();
      this.anim = false;
    }, 10);
  }

  @HostListener('window:scroll', [])
  onWindowScroll() {
    if (this.anim) {
      return false;
    }
    this.anim = true;
    setTimeout(() => {
      this.process();
      this.anim = false;
    }, 10);

  }

  private process() {
    const offset = this.window.pageYOffset || this.document.documentElement.scrollTop || this.document.body.scrollTop || 0;
    const offset2 = this.el.nativeElement.offsetTop;


    if (offset2 - offset > 20) {

      this.move(0);
    } else {
      this.move(100);
    }
  }

  private move(percentage: number) {

    const moveX = (500 - percentage * 5) * this.factor;
    move(this.contentView.nativeElement)
      .set('opacity', percentage / 100)
      .x(moveX)
      .end();
    move(this.pictureView.nativeElement)
      .set('opacity', percentage / 100)
      .x(-moveX)
      .end();
  }
}
