import {Component} from '@angular/core';
import {Meta, Title} from '@angular/platform-browser';
import {JsonLdService} from '../shared/json-ld.service';

@Component({
  selector: 'app-page-functionality',
  templateUrl: './page-functionality.component.html',
  styleUrls: ['./page-functionality.component.scss']
})
export class PageFunctionalityComponent {

  tiles = [

    {
      title: 'Courses and Qualifications',
      text: 'OnDemand Education includes Courses and Qualifications from Professional Education Partners and internal training material ' +
        'approved by Corporates, which could range from product training to certification on standard operating procedures. ' +
        'Any pre-developed educational material can be offered as OnDemand Education on TRISCOMS&reg;.',
      url: 'images/feature-icons/09-courses-and-qualifications.png',
      altText: 'Certificate Icon'
    },
    {
      title: 'Multi Media Education',
      text: 'OnDemand Education does not imply “boring education”. TRISCOMS&reg; incorporates engaging multi-media, ' +
        'SCORM-compliant e-learning activities. Our SCORM engine is based on Moodle.',
      url: 'images/feature-icons/10-multi-media-education.png',
      altText: 'Video Icon'
    },
    {
      title: 'Assessments Online',
      text: 'No need to complete paper-based assessments. TRISCOMS&reg; incorporates over 24 different online assessment tools to make ' +
        'sure that professionals remain engaged.',
      url: 'images/feature-icons/11-assessments-online.png',
      altText: 'Assessments Online Icon'
    },
    {
      title: 'OnDemand Updates',
      text: 'OnDemand Education is designed for the “Busy Professional” that demands access to educational material, as and when they' +
        ' have time for training. Even more so for OnDemand Updates – develop and publish educational content in 30-min offerings' +
        ' to maximise time in Professionals’ diaries.   ',
      url: 'images/feature-icons/12-on-demand-updates.png',
      altText: 'OnDemand Updates Icon'
    },

  ];

  tiles2 = [

    {
      title: 'Digital Whiteboards and Annotations',
      text: 'Interactive Online Education sessions and webinars encourage collaboration with an interactive digital whiteboard ' +
        'integrated within the virtual classroom.',
      url: 'images/feature-icons/13-digital-whiteboards-and-annotations.png',
      altText: 'Digital Whiteboards and Annotations Icon'
    },
    {
      title: 'Live Session Recordings',
      text: 'Completed recordings available in HD MP4 format within minutes after a session, and can easily be downloaded for ' +
        'later reference and use as OnDemand Online Education. ',
      url: 'images/feature-icons/14-live-session-recordings.png',
      altText: 'Live Session Recordings Icon'
    },
    {
      title: 'Screen Sharing',
      text: 'With screen sharing functionality, facilitators can showcase how to use complex software, equipment and processes via ' +
        'the real-time broadcasting of their screens.',
      url: 'images/feature-icons/15-screen-sharing.png',
      altText: 'Screen Sharing Icon'
    },
    {
      title: 'Breakout Rooms for Group Sessions',
      text: 'An innovative functionality that encourages group collaboration by splitting the audience into groups i.e. breakout rooms, ' +
        'to discuss and share important ideas and concepts while working together.',
      url: 'images/feature-icons/16-breakout-rooms-for-group-sessions.png',
      altText: 'Breakout Rooms for Group Sessions Icon'
    },
    {
      title: 'Notes in Realtime',
      text: 'Notes is an interactive tool that allow participants to get the most information out of each virtual training session.' +
        ' Notes can be broadcast in real-time to all participants and can be saved as PDF file. ',

      url: 'images/feature-icons/17-notes-in-realtime.png',
      altText: 'Notes in Realtime Icon'
    },
    {
      title: 'Live Quizzes and Assessments',
      text: 'Complete your course assessments following a module and while the facilitator is online. ' +
        'Completion or CPD Certificates are issued to your Learning Portfolio upon completion.',

      url: 'images/feature-icons/18-live-quizzes-and-assessments.png',
      altText: 'Live Quizzes and Assessment Icon'
    },

  ];

  tiles3 = [

    {
      title: 'Cross Platform',
      text: ' TRISCOMS&reg; Event Central is available on Android and iOS.',
      url: 'images/feature-icons/19-cross-platform.png',
      altText: ''
    },
    {
      title: 'TRISCOMS&reg; Calendar',
      text: 'All Face-to-Face Education sessions are published in the TRISCOMS&reg; Calendar',
      url: 'images/feature-icons/20-triscoms-calendar.png',
      altText: 'Cross Platform Icon'
    },
    {
      title: 'Unique Professional QR Code',
      text: 'Professionals are scanned with a unique QR code linked to TRISCOMS&reg; to log attendance for events.' +
        ' We even log GPS co-ordinates of the scan for audit and compliance purposes.',
      url: 'images/feature-icons/21-unique-professional-qr-code.png',
      altText: 'Unique Professional QR Code Icon'
    },
  ];

  tiles4 = [

    {
      title: 'Professional Development Portfolios',
      text: ' Export Professional Development Portfolios for submission to stakeholders  including Corporate Employers and Regulators.',
      url: 'images/feature-icons/22-professional-development-portfolios.png',
      altText: 'Professional Development Portfolios Icon'
    },
    {
      title: 'Secure Storage',
      text: 'Secure Storage of attendance and CPD certificates within a secure and industry-specific platform.',
      url: 'images/feature-icons/23-secure-storage.png',
      altText: 'Secure Storage Icon'
    }, {
      title: 'Upload and Consolidate all Professional Education',
      text: 'Upload attendance and CPD certificates for education completed outside of ED.online.',
      url: 'images/feature-icons/24-upload-and-consolidate.png',
      altText: 'Upload and Consolidate all Professional Education Icon'
    },
    {
      title: 'CPD Manager on ED.online',
      text: 'Manages Professional Development based on industry-specific CPD requirements.',
      url: 'images/feature-icons/25-cpd-manager.png',
      altText: 'CPD Manager on ED.online Icon'
    },
  ];

  tiles5 = [

    {
      title: '24/7 Remote Access to Reports',
      text: ' Sponsors and Event Service Providers can access project reports on the ED.online platform and PSS' +
        ' Knowledge Center remotely 24/7, at the click of a button.',
      url: 'images/feature-icons/26-remote-access.png',
      altText: '24/7 Remote Access to Reports Icon'
    },
    {
      title: 'Custom Static Reports',
      text: 'Custom project reports can be built that meet specific compliance requirements that enable real-time access to information.',
      url: 'images/feature-icons/27-custom-static-reports.png',
      altText: 'Custom Static Reports Icon'
    },
    {
      title: 'Dynamic Reporting Options',
      text: 'Powered by Microsoft’s Power BI, service providers can get access to leading Dynamic Reporting functionalities that translate data into actionable insights.',
      url: 'images/feature-icons/28-dynamic-reporting-options.png',
      altText: 'Dynamic Reporting Options Icon'
    },
  ];

  constructor(private titleService: Title, private metaService: Meta, private jsonLdService: JsonLdService) {

    titleService.setTitle('INTEGRATED HYBRID LEARNING TRISCOMS® offers a fully-responsive platform for Professional Education');

    jsonLdService.updateJson({
      '@context': 'http://schema.org',
      '@type': 'WebSite',
      name: 'Pioneering Solutions Studio (PSS)',
      url: 'https://www.ps.studio/functionality-and-features'
    });

    metaService.addTags([
      {name: 'keywords', content: 'INTEGRATED HYBRID LEARNING, OnDemand Online Education, Interactive Education'},
      {
        name: 'description',
        content: 'INTEGRATED HYBRID LEARNING a fully-responsive platform for Professional Education with OnDemand Online Education, Interactive Education via Virtual Campus & more'
      },
      {name: 'robots', content: 'index, follow'}
    ]);

  }

}
