<h2 class="text-center pt-5">{{slidesStore[key].heading}}</h2>

<owl-carousel-o [options]="customOptions">

  <ng-container *ngFor="let slide of slidesStore[key].slides">
    <ng-template [id]="slide.id" carouselSlide>
      <div class="slide-logo p-4">
        <picture>
          <source srcset="https://pssstaticwebstorage.blob.core.windows.net/images/logos/client-{{slide.id}}.webp" type="image/webp">
          <source srcset="https://pssstaticwebstorage.blob.core.windows.net/images/logos/client-{{slide.id}}.png" type="image/png">
          <img [alt]="slide.alt" [title]="slide.alt" class="mx-auto"
               src="https://pssstaticwebstorage.blob.core.windows.net/images/logos/client-{{slide.id}}.png" width="150">
        </picture>
      </div>
    </ng-template>
  </ng-container>

</owl-carousel-o>
