import {Component, Input, OnInit} from '@angular/core';
import {IndustryTile} from '../industry-tile';

@Component({
  selector: 'app-industry-tile',
  templateUrl: './industry-tile.component.html',
  styleUrls: ['./industry-tile.component.scss']
})
export class IndustryTileComponent implements OnInit {

  @Input() tile: IndustryTile;

  constructor() {
  }

  ngOnInit(): void {
  }

  barUrl() {
    return this.tile ? `url("https://pssstaticwebstorage.blob.core.windows.net/images/industries/${this.tile.imageKey} Bar.png")` : '';
  }

}
