import {Component} from '@angular/core';
import {Meta, Title} from '@angular/platform-browser';
import {JsonLdService} from '../shared/json-ld.service';

@Component({
  selector: 'app-page-disclaimer',
  templateUrl: './page-disclaimer.component.html',
  styleUrls: ['./page-disclaimer.component.scss']
})
export class PageDisclaimerComponent {


  constructor(private titleService: Title, private metaService: Meta, private jsonLdService: JsonLdService) {

    titleService.setTitle('PSS - Disclaimer');

    // jsonLdService.updateJson({
    //   '@context': 'http://schema.org',
    //   '@type': 'WebSite',
    //   name: 'education',
    //   url: 'https://angular.io'
    // });
    //
    // metaService.addTags([
    //   {name: 'keywords', content: 'Angular, Universal, Example'},
    //   {name: 'description', content: 'Angular Universal Example'},
    //   {name: 'robots', content: 'index, follow'}
    // ]);
  }

}
