import { Component, OnInit } from '@angular/core';
import {Meta, Title} from '@angular/platform-browser';
import {JsonLdService} from '../shared/json-ld.service';

@Component({
  selector: 'app-page-privacy-claus',
  templateUrl: './page-privacy-claus.component.html',
  styleUrls: ['./page-privacy-claus.component.scss']
})
export class PagePrivacyClausComponent  {

  constructor(private titleService: Title, private metaService: Meta, private jsonLdService: JsonLdService) {

    titleService.setTitle('PSS - Privacy Claus');

    jsonLdService.updateJson({
      '@context': 'http://schema.org',
      '@type': 'WebSite',
      name: 'education',
      url: 'https://angular.io'
    });

    metaService.addTags([
      {name: 'keywords', content: 'Angular, Universal, Example'},
      {name: 'description', content: 'Angular Universal Example'},
      {name: 'robots', content: 'index, follow'}
    ]);
  }

}
