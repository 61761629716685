<div [ngClass]="index == 0 ? 'first' : ''" class="timeline-item period ">
  <div class="timeline-info"></div>
  <div class="">
    <h2 class="timeline-title mb-sm-4 mb-md-0">{{item.year}}</h2>
  </div>

</div>
<div [id]="'item' + index"
     [ngClass]="index % 2 == 0 ? 'flex-md-row-reverse' : 'flex-row'"
     class="d-flex timeline-item flex-wrap flex-md-nowrap">


  <div #pictureView class="timeline-image m-md-3  m-lg-5">
    <picture>
      <source srcset="https://pssstaticwebstorage.blob.core.windows.net/images/timeline/{{item.imageStatic}}.webp" type="image/webp">
      <source srcset="https://pssstaticwebstorage.blob.core.windows.net/images/timeline/{{item.imageStatic}}.png" type="image/png">
      <img alt="{{item.year}}.png" src="https://pssstaticwebstorage.blob.core.windows.net/images/timeline/{{item.imageStatic}}.png">
    </picture>
  </div>
  <div class="timeline-marker"></div>
  <div [ngClass]="index % 2 == 0 ? 'justify-content-end' : 'justify-content-start'"
       class="timeline-content  d-flex  m-md-3 m-lg-5"
  >

    <p #contentView [innerHTML]="item.content"></p>
  </div>


</div>
