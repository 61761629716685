export class ContactForm {
  public name = '';
  public email = '';
  public contact = '';
  public company = '';
  public message = '';
  invalid: any;

  toStruct() {
    return {
      name: this.name,
      email: this.email,
      contact: this.contact,
      company: this.company,
      message: this.message,
      invalid: this.invalid
    };
  }
}
