import {Component, OnInit} from '@angular/core';
import {ContactUsService} from '../contact-us.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Meta, Title} from '@angular/platform-browser';
import {JsonLdService} from '../shared/json-ld.service';
import {Router} from '@angular/router';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {ReCaptchaService} from 'angular-recaptcha3';
import { MailerService } from '../services/mailer.service';
import { Mail } from '../services/mailer/mail';
import { ContactForm } from '../services/mailer/contact-form';

@Component({
  selector: 'app-page-contact-us',
  templateUrl: './page-contact-us.component.html',
  styleUrls: ['./page-contact-us.component.scss']
})
export class PageContactUsComponent implements OnInit {

  // public contactForm: FormGroup;
  contactForm = new ContactForm();
  errors = [];
  recaptchaSiteKey = environment.site_key;

  myForm = this.formBuilder.group({
    name: ['', Validators.required],
    email: ['', Validators.required],
    contact: ['', Validators.required],
    company: ['', Validators.required],
    message: ['', Validators.required]
  });

  constructor(
    private service: ContactUsService,
    private formBuilder: FormBuilder,
    private httpClient: HttpClient,
    private titleService: Title,
    private metaService: Meta,
    private router: Router,
    private jsonLdService: JsonLdService,
    private recaptchaService: ReCaptchaService,
    private mailerService: MailerService) {

    titleService.setTitle('Contact us for all your Integrated Hybrid Learning needs ');

    jsonLdService.updateJson({
      '@context': 'http://schema.org',
      '@type': 'WebSite',
      name: 'Pioneering Solutions Studio (PSS)',
      url: 'https://www.ps.studio/contact-us'
    });

    metaService.addTags([
      {name: 'keywords', content: ''},
      {name: 'description', content: '+27 12 111 7000'},
      {name: 'robots', content: 'index, follow'}
    ]);
  }

  ngOnInit(): void {
    /*this.contactForm = this.formBuilder.group({
      name: [''],
      company: [''],
      contact: [''],
      email: [''],
      message: [''],
      invalid: false,
      recaptchaToken: ['']
    });*/
  }

  sendMail(){
    this.recaptchaService.execute({action: 'importantAction'}).then(token => {
      console.log('recaptcha token received');
    });
  }

  onCaptchaResponse($event: any) {
    this.sendForm($event);
  }

  onCaptchaExpired($event: any) {
    console.log('expired');
    console.log($event);
  }

  sendForm(recaptchaToken){
    const newForm = new Mail(this.myForm);
    console.log(newForm);
    this.mailerService.sendMailer(newForm.toJson()).subscribe(
      (res) => {
        this.router.navigate(['thank-you']);
      },
      (err) => console.log(err)
    );
  }
}
