<div class="card product-card border-round-top shadow-lg ">
  <div class="card-header text-center brand-dark border-round-top">CORPORATE CLIENTS</div>
  <img alt="" src=""
  >

  <picture class="card-img-top rounded-0" style="height: 129px">
    <source srcset="https://pssstaticwebstorage.blob.core.windows.net/images/banners/corporate-clients.webp" type="image/webp">
    <source srcset="https://pssstaticwebstorage.blob.core.windows.net/images/banners/corporate-clients.png" type="image/png">
    <img alt="Corporate Clients" class="card-img-top rounded-0" style="height: 129px" src="https://pssstaticwebstorage.blob.core.windows.net/images/banners/corporate-clients.png">
  </picture>
  <div class="card-img-overlay">
    <img alt="Corporate Clients Icon" src="https://pssstaticwebstorage.blob.core.windows.net/images/icons/corporate-icon.png">
  </div>
  <div class="card-group m-0 ">
    <div class="card m-0 border-bottom-0 rounded-0 border-right-red inner-card">
      <div class="card-body">

        <div class="card-title text-center ">Educate Professionals via our industry platforms</div>
        <div class="card-subtitle text-center">Software as a Service for Sponsors</div>
        <hr class="w-65 bg-grey">

        <div class="card-text" style="height: 400px">
          <ul class="triangle triangle-red text-left">
            <li>Use TRISCOMS&reg; ED.online as a Sponsor to educate our Professional clients about your cause or product
              offering. Build your brand.
            </li>
            <li>Bring Your Own Content (BYOC) or source content from our Professional Education Partners.</li>
            <li>Only pay for Professionals trained.</li>
            <li>Includes all hosting, support, and tech cost</li>

          </ul>
        </div>
      </div>
      <div class="card-footer  text-center bg-grey-light rounded-0">
        <img alt="" class=" rounded-0 mx-auto" src="https://pssstaticwebstorage.blob.core.windows.net/images/logos/ed-online.png"
             style="height: 50px;">
      </div>
    </div>
    <div class="card m-0 border-0 rounded-0 inner-card">
      <div class="card-body">
        <div class="card-title text-center">Educate your Staff or Clients via your own TRISCOMS&reg; platform</div>
        <div class="card-subtitle text-center">Platform as a Service for Employers</div>
        <hr class="w-65 bg-light">
        <div class="card-text">
          <ul class="triangle triangle-red text-left">
            <li>Use TRISCOMS&reg; to train your staff on your own white-labelled platform.</li>
            <li>Bring Your Own Content (BYOC) or source content from our Professional Education Partners.</li>
            <li>Only pay per staff member, per month.</li>
            <li>Unlimited Education*.</li>
            <li>Includes all hosting, support, and tech cost.</li>

          </ul>
        </div>
      </div>
      <div class="card-footer  text-center bg-grey-light">
        <img alt="" class=" rounded-0 mx-auto" src="https://pssstaticwebstorage.blob.core.windows.net/images/logos/triscoms-logo-corporate.png"
             style="height: 50px;">

      </div>
    </div>
  </div>


  <div class="card-footer text-center bg-grey-soft">
    Support Public Interest Projects with SaaS or PaaS.<br>
    <i>Use TRISCOMS&reg; or ED.online to educate beneficiaries on Public Interest Projects.</i>

  </div>
  <div class="stripe bg-white"></div>
  <div class="stripe brand-dark"></div>
</div>

