import {Component, OnInit} from '@angular/core';
import {Meta, Title} from '@angular/platform-browser';
import {JsonLdService} from '../shared/json-ld.service';

@Component({
  selector: 'app-page-about-us',
  templateUrl: './page-about-us.component.html',
  styleUrls: ['./page-about-us.component.scss']
})
export class PageAboutUsComponent implements OnInit {

  constructor(private titleService: Title, private metaService: Meta, private jsonLdService: JsonLdService) {

    titleService.setTitle('Living our vision of empowering people through EdTech');

     jsonLdService.updateJson({
       '@context': 'http://schema.org',
       '@type': 'WebSite',
       name: 'Pioneering Solutions Studio (PSS)',
       url: 'https://www.ps.studio/about-us'
     });
    
     metaService.addTags([
       {name: 'keywords', content: 'Angular, Universal, Example'},
       {name: 'description', content: 'A leader in Education Technology (EdTech) and used in multinational programs by organisations like the United Nations and private sector alike.'},
       {name: 'robots', content: 'index, follow'}
     ]);
  }

  ngOnInit(): void {
  }

}
