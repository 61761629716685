import {Component} from '@angular/core';
import {Meta, Title} from '@angular/platform-browser';
import {JsonLdService} from '../shared/json-ld.service';

@Component({
  selector: 'app-page-corporate',
  templateUrl: './page-corporate.component.html',
  styleUrls: ['./page-corporate.component.scss']
})
export class PageCorporateComponent {


  constructor(private titleService: Title, private metaService: Meta, private jsonLdService: JsonLdService) {

    titleService.setTitle('Educational platforms supporting industries Froma A to Z');

     jsonLdService.updateJson({
       '@context': 'http://schema.org',
       '@type': 'WebSite',
       name: 'Pioneering Solutions Studio (PSS)',
       url: 'https://www.ps.studio/corporate-clients'
     });
    
     metaService.addTags([
      {name: 'keywords', content: 'Professional compliance, Education Platforms'},
       {name: 'description', content: 'We develop, implement and maintain online Professional compliance and Education Platforms for various industries from Medical to Mining we Support them all'},
       {name: 'robots', content: 'index, follow'}
     ]);

  }

}
