import {ContactForm} from './contact-form';
import {FormGroup} from '@angular/forms';

export class Mail {
  from = '';
  to = '';
  replyTo = '';
  subject = '';
  content = '';
  senderId = '';


 // this code can become a struct
  constructor(private form: FormGroup) {
    this.from = 'support@ps.studio';
    this.to = 'edtechleads@ps.studio';
    this.replyTo = form.value.email;
    this.subject = 'Edtech Lead - Website Enquiry';
    this.senderId = 'psstudio';

    this.content += '<p><strong>Name: </strong> ' + form.value.name + '</p>';
    this.content += '<p><strong>Email: </strong> ' + form.value.email + '</p>';
    this.content += '<p><strong>Phone: </strong> ' + form.value.contact + '</p>';
    // tslint:disable-next-line:indent
	   this.content += '<p><strong>Company Name: </strong> ' + form.value.company + '</p>';
    this.content += '<p><strong>Message:</strong></p>';
    this.content += '<p>' + form.value.message + '</p>';
  }

  setToAddress(toAddress: string) {
    this.to = toAddress;
  }
  setFromAddressAsSenderEmail() {
    this.from = this.replyTo;
  }

  setSubject(subject: string) {
    this.subject = subject;
  }

  appendNameToSubject(name: string) {
    this.subject = this.subject + " - " + name;
  }
  
  toJson() {
    return {
      from: this.from,
      to: this.to,
      replyTo: this.replyTo,
      subject: this.subject,
      content: this.content,
      senderId: this.senderId
    };
  }

}
