import {Component, OnInit} from '@angular/core';
import {Meta, Title} from '@angular/platform-browser';
import {JsonLdService} from '../shared/json-ld.service';

@Component({
  selector: 'app-page-app-user-agreement',
  templateUrl: './page-app-user-agreement.component.html',
  styleUrls: ['./page-app-user-agreement.component.scss']
})
export class PageAppUserAgreementComponent implements OnInit {

  constructor(private titleService: Title, private metaService: Meta, private jsonLdService: JsonLdService) {

    titleService.setTitle('App User Agreement');

    // jsonLdService.updateJson({
    //   '@context': 'http://schema.org',
    //   '@type': 'WebSite',
    //   name: 'education',
    //   url: 'https://angular.io'
    // });
    //
    // metaService.addTags([
    //   {name: 'keywords', content: 'Angular, Universal, Example'},
    //   {name: 'description', content: 'Angular Universal Example'},
    //   {name: 'robots', content: 'index, follow'}
    // ]);

  }

  ngOnInit(): void {
  }

}
