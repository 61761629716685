<div class="banner-image">

  <video autoplay height="100%" loop muted poster="https://pssstaticwebstorage.blob.core.windows.net/images/banners/hybrid-learning-banner.png"
         width="100%">
    <source src="https://pssstaticwebstorage.blob.core.windows.net/images/banners/hybrid-learning-banner.webm" type="video/webm">
    <source src="https://pssstaticwebstorage.blob.core.windows.net/images/banners/hybrid-learning-banner.mp4" type="video/mp4">
    <p class="warning">Your browser does not support HTML5 video.</p>
  </video>

  <div class="banner-text">
    <h1>INTEGRATED HYBRID LEARNING</h1>
    <p>TRISCOMS&reg; offers a fully-responsive platform<br>for Professional Education</p>
  </div>
</div>
<div class="container pt-5 text-center">
  <h4 class="text-center">EMPOWERING AT THE CLICK OF A BUTTON</h4>
  <p class="pt-3">
    Hybrid education is all about recognising that different people learn in different ways and that there is no right
    or wrong way to approach the Professional Education. That is why TRISCOMS&reg; does not only cater to online or
    face-to-face education as standalone silos – we choose to see the education of Professionals holistically. TRISCOMS&reg;
    provides a fully integrated approach to the Hybrid Education of professionals and allows Corporates and Professional
    Education Partners to consolidate training across various mediums and channels.
  </p>
  <p class="pt-3">
    TRISCOMS&reg; provides industry Professionals, Corporates, and Professional Education Partners with a Microsoft
    Azure Hyper Cloud hosted Hybrid
    education experience that is geared to accommodate SaaS and PaaS licensing models .
  </p>
  <p class="pt-3">
    TRISCOMS&reg; is based around online Continuing Professional Development (CPD) standards and is built to consolidate
    a professional industry’s CPD
    (educational and compliance) requirements within a secure industry-specific environment along with any education
    that the Professional has completed with his/her Corporate employer. TRISCOMS&reg; provides Professionals,
    Corporates, and Professional Education Partners with the right tools, resources, and leading compliance features to
    comfortably keep up to date with their CPD and education demands. TRISCOMS&reg; addresses various key requirements
    for
    education with professional industries.
  </p>
</div>

<div class="container mt-1 mb-5">
  <h4 class="text-center">KEY FEATURES THAT MAKE TRISCOMS<small><sup>&reg;</sup></small> DIFFERENT</h4>

</div>

<div class="container-fluid background-dark " style="padding-bottom: 150px">
  <div class="container pt-5">
    <div class="d-flex flex-row flex-wrap flex-lg-nowrap flex-xl-nowrap justify-content-center align-items-center">

      <div class="text-center">
        <video autoplay class="top-icon" loop muted poster="https://pssstaticwebstorage.blob.core.windows.net/images/vectors/lms-vector.png">
          <source src="https://pssstaticwebstorage.blob.core.windows.net/images/vectors/lms-vector.webm" type="video/webm">
          <source src="https://pssstaticwebstorage.blob.core.windows.net/images/vectors/lms-vector.mp4" type="video/mp4">
          <p class="warning">Your browser does not support HTML5 video.</p>
        </video>
      </div>
      <div class="">
        <h4 class="pb-4">OnDemand Online Education</h4>
        <p>
          OnDemand Online Education via our Learner Management System (LMS) is pre-developed and recorded education that
          can be accessed at the convenience of the Professional. OnDemand Online Education can be CPD Accredited or
          not.
        </p>
        <p class="font-weight-bold">
          OnDemand Online Education is offered as:
        </p>
        <ul class="dashed">
          <li>Registered Qualifications (minimum 12-month course);</li>
          <li>Short Courses (10 to 40 hours to complete) or</li>
          <li>Journals, Articles, Newsletters or Knowledge Updates</li>
        </ul>
        <p> On Demand Education are available 24 hours a day, 7 days a week
        </p>
        <ul class="dashed">
          <li>
            we are ready when you are.

          </li>
        </ul>
      </div>
    </div>
  </div>
</div>
<div class="container-fluid">
  <div class="container">
    <div class="d-flex flex-wrap justify-content-center mx-auto" style="margin-top: -140px">
      <div *ngFor="let tile of tiles" class="card m-2 tile shadow " style="">
        <img [src]="'https://pssstaticwebstorage.blob.core.windows.net/' + tile.url" [alt]="tile.altText" class="card-img-top  mx-auto mt-4">
        <div class="card-body text-center">
          <div [innerHTML]="tile.title" class="card-title font-weight-bold"></div>
          <div [innerHTML]="tile.text" class="card-text"></div>
        </div>


      </div>
    </div>
  </div>


  <div class="container py-5">
    <div
      class="d-flex flex-row flex-wrap-reverse flex-lg-nowrap flex-xl-nowrap justify-content-center align-items-center">
      <div class="">
        <h4 class="pb-4">Interactive Education via Virtual Campus</h4>
        <p>Interactive Online Education allows Professionals to attend online live interactive sessions from the
          convenience of their own home or office. The TRISCOMS&reg; Virtual Campus allows for a range of interactive
          features that will ensure maximum educational return.</p>
        <p>
          TRISCOMS&reg; Virtual Campus allows Professional Education Partners and Corporates to setup their own
          interactive virtual education sessions. Professionals simply RSVP and attend the session. TRISCOMS&reg; will
          automatically generate your event attendance schedule and generate attendance certificates to the
          Professional’s
          Professional Development Portfolio upon closure of the event.
        </p>
        <ul class="dashed">
          <li>Present professionally using leading browser-based webinar software. Our Virtual Classrooms are based on
            Newrow technology.
          </li>
          <li>Interactive sessions from remote locations</li>
          <li>Collaborative features that engage audience and enrich learning experience</li>
          <li>Professionals can access sessions by the click of a button - no downloads are required</li>
        </ul>
      </div>
      <div class="text-center">
        <video autoplay class="top-icon" loop muted poster="https://pssstaticwebstorage.blob.core.windows.net/images/vectors/virtual-campus-vector.png">
          <source src="https://pssstaticwebstorage.blob.core.windows.net/images/vectors/virtual-campus-vector.webm" type="video/webm">
          <source src="https://pssstaticwebstorage.blob.core.windows.net/images/vectors/virtual-campus-vector.mp4" type="video/mp4">
          <p class="warning">Your browser does not support HTML5 video.</p>
        </video>
      </div>
    </div>

  </div>


  <div class="container">
    <div class="d-flex flex-wrap justify-content-center mx-auto" style="margin-bottom: -140px">
      <div *ngFor="let tile of tiles2" class="card m-2 tile shadow" style="">
        <img [src]="'https://pssstaticwebstorage.blob.core.windows.net/' + tile.url" [alt]="tile.altText" class="card-img-top  mx-auto mt-4">
        <div class="card-body text-center">
          <div [innerHTML]="tile.title" class="card-title font-weight-bold"></div>
          <div class="card-text">{{tile.text}}</div>
        </div>


      </div>
    </div>
  </div>

</div>

<div class="container-fluid background-dark" style="padding-top: 180px; padding-bottom: 150px">
  <div class="container pb-5">
    <div class="d-flex flex-row flex-wrap flex-lg-nowrap flex-xl-nowrap justify-content-center align-items-center">
      <div class="text-center">

        <video autoplay class="top-icon" loop muted poster="https://pssstaticwebstorage.blob.core.windows.net/images/vectors/event-central-vector.png">
          <source src="https://pssstaticwebstorage.blob.core.windows.net/images/vectors/event-central-vector.webm" type="video/webm">
          <source src="https://pssstaticwebstorage.blob.core.windows.net/images/vectors/event-central-vector.mp4" type="video/mp4">
          <p class="warning">Your browser does not support HTML5 video.</p>
        </video>

      </div>
      <div class="">
        <h4 class="pb-4">Face-to-Face via Event Central</h4>
        <p>A Complete Portfolio for all Type of Professionals</p>
        <p>TRISCOMS&reg; Professional Development Portfolio (PDP)
          consolidates all Professional Education completed by the Professional in a single place. All Online OnDemand,
          Online Interactive and Face to Face CPD or completion certificates are collected here for ease of access by
          the
          Professional. Professionals can even upload education certificates from other providers for inclusion in their
          PDP.</p>
        <p>The PDPs deployed on TRISCOMS&reg; ED.online in industries that have regulatory reporting requirements also
          offer CPD Manager modules for Professionals that need to report to Regulators on the nature and frequency of
          their education (available in South Africa and selected Southern African countries only).
        </p>

      </div>
    </div>
  </div>
</div>
<div class="container-fluid background-light" style="">
  <div class="container">
    <div class="d-flex flex-wrap justify-content-center mx-auto" style="margin-top: -140px">
      <div *ngFor="let tile of tiles3" class="card m-2 tile shadow " style="">
        <img [src]="'https://pssstaticwebstorage.blob.core.windows.net/' + tile.url" [alt]="tile.altText" class="card-img-top  mx-auto mt-4">
        <div class="card-body text-center">
          <div [innerHTML]="tile.title" class="card-title font-weight-bold"></div>
          <div [innerHTML]="tile.text" class="card-text"></div>
        </div>


      </div>
    </div>
  </div>


  <div class="container py-5">
    <div
      class="d-flex flex-row flex-wrap-reverse flex-lg-nowrap flex-xl-nowrap justify-content-center align-items-center">
      <div class="">
        <h4 class="pb-4">Education Consolidation via Professional Development Portfolio</h4>
        <p>
          OnDemand Online Education via LMS is pre-developed and recorded education that can be accessed at the
          convenience of the Professional.<br>
          OnDemand Online Education can be CPD Accredited or not.
        </p>
        <p>
          OnDemand Online Education is offered as:
        </p>
        <ul>
          <li>Registered Qualifications (minimum 12-month course);</li>
          <li>Short Courses (10 to 40 hours to complete) or</li>
          <li>Journals, Articles, Newsletters or Knowledge Updates</li>
        </ul>
        <p> On Demand Education available 24 hours a day. 7 days a week<br>
          - we are ready when you are.
        </p>
      </div>
      <div class="text-center">

        <video autoplay class="top-icon" loop muted poster="https://pssstaticwebstorage.blob.core.windows.net/images/vectors/individual-vector.png">
          <source src="https://pssstaticwebstorage.blob.core.windows.net/images/vectors/individual-vector.webm" type="video/webm">
          <source src="https://pssstaticwebstorage.blob.core.windows.net/images/vectors/individual-vector.mp4" type="video/mp4">
          <p class="warning">Your browser does not support HTML5 video.</p>
        </video>
      </div>

    </div>

  </div>


  <div class="container">
    <div class="d-flex flex-wrap justify-content-center mx-auto" style="margin-bottom: -140px">
      <div *ngFor="let tile of tiles4" class="card m-2 tile shadow" style="">
        <img [src]="'https://pssstaticwebstorage.blob.core.windows.net/' + tile.url" [alt]="tile.altText" class="card-img-top  mx-auto mt-4">
        <div class="card-body text-center">
          <div [innerHTML]="tile.title" class="card-title font-weight-bold"></div>
          <div class="card-text">{{tile.text}}</div>
        </div>


      </div>
    </div>
  </div>
</div>

<div class="container-fluid background-dark" style="padding-top: 180px; padding-bottom: 150px ">

  <div class="container py-5">
    <div class="d-flex flex-row flex-wrap flex-lg-nowrap flex-xl-nowrap justify-content-center align-items-center">
      <div class="text-center">
        <video autoplay class="top-icon" loop muted poster="https://pssstaticwebstorage.blob.core.windows.net/images/vectors/knowledge-center-vector.png">
          <source src="https://pssstaticwebstorage.blob.core.windows.net/images/vectors/knowledge-center-vector.webm" type="video/webm">
          <source src="https://pssstaticwebstorage.blob.core.windows.net/images/vectors/knowledge-center-vector.mp4" type="video/mp4">
          <p class="warning">Your browser does not support HTML5 video.</p>
        </video>
      </div>
      <div class="">
        <h4 class="pb-4">Reporting via Knowledge Center</h4>
        <p>
          The Solution to Quantifying ROI of Education</p>
        <p>TRISCOMS&reg; Knowledge Center provides big data management solution to Professional Education Partners and
          Corporates that wish to view the results of education programmes for many professionals. Knowledge Center
          combines the results of Professionals Development Portfolios of appropriate Professionals into a single view
          and makes it easy to track education trends and results at programme, course, profession or any other data
          layer that our Professional Education Partner and Corporate clients may wish to view.
        </p>
        <p>
          Knowledge Center is powered by Microsoft Power BI and Reporting Services and even exports dashboards to
          Microsoft Power Point for presentation-ready downloads. Don’t spend hours formatting education programme
          results if it can all be done for you.
        </p>
      </div>
    </div>
  </div>


</div>

<div class="container-fluid background-light" style="">
  <div class="container">
    <div class="d-flex flex-wrap justify-content-center mx-auto" style="margin-top: -140px">
      <div *ngFor="let tile of tiles5" class="card m-2 tile shadow " style="">
        <img [src]="'https://pssstaticwebstorage.blob.core.windows.net/' + tile.url" [alt]="tile.altText" class="card-img-top  mx-auto mt-4">
        <div class="card-body text-center">
          <div [innerHTML]="tile.title" class="card-title font-weight-bold"></div>
          <div [innerHTML]="tile.text" class="card-text"></div>
        </div>


      </div>
    </div>
  </div>


  <div class="container py-5">
    <div
      class="d-flex flex-row flex-wrap-reverse flex-lg-nowrap flex-xl-nowrap justify-content-center align-items-center">
      <div class="">
        <h4 class="pb-4">Hyper Cloud for Professionals</h4>
        <p><i>Where performance meets security</i></p>
        <p>Security</p>
        <p>At Pioneering Solutions Studio, we prioritise our user’s security, which is why we host in the only Hyper
          Cloud
          environment in Africa – Microsoft Azure. Our users and clients can rest assured knowing that their data will
          never leave the African Continent and that TRISCOMS&reg; is South African Protection of Personal Information
          Act (POPIA) compliant.

        </p>
        <p>
          Our mission to develop and implement world-class online EdTech solutions is underpinned by
          our commitment to quality. We are a fully accredited Microsoft Partner and adhere to Microsoft International
          Standards during our solution-development life cycles. Our Microsoft Partner accreditations range from
          Authorised Education Partner (AEP) to Imagine Academy and Gold Cloud Platform, which enables us to implement
          leading Microsoft solutions into TRISCOMS&reg;.
        </p>
      </div>
      <div class="text-center">

        <video autoplay class="top-icon" loop muted poster="https://pssstaticwebstorage.blob.core.windows.net/images/vectors/hyper-cloud-vector.png">
          <source src="https://pssstaticwebstorage.blob.core.windows.net/images/vectors/hyper-cloud-vector.webm" type="video/webm">
          <source src="https://pssstaticwebstorage.blob.core.windows.net/images/vectors/hyper-cloud-vector.mp4" type="video/mp4">
          <p class="warning">Your browser does not support HTML5 video.</p>
        </video>
      </div>

    </div>

  </div>
</div>
