<div class="home-banner-image">

  <video autoplay height="100%" loop muted poster="https://pssstaticwebstorage.blob.core.windows.net/images/banners/home-page-banner-1280.jpg" width="100%">
    <source src="https://pssstaticwebstorage.blob.core.windows.net/images/banners/home-page-banner.webm" type="video/webm">
    <source src="https://pssstaticwebstorage.blob.core.windows.net/images/banners/home-page-banner.mp4" type="video/mp4">
    <p class="warning">Your browser does not support HTML5 video.</p>
  </video>

  <div class="home-banner-text">
    <h1>EMPOWERING PEOPLE THROUGH<br> EDUCATION TECHNOLOGY</h1>
    <p>TRISCOMS&reg; Professional Education Technology (EdTech) Platform has comprehensive Features and
      Functionality</p>
    <button class="btn btn-outline-light" routerLink="functionality-and-features">LEARN MORE</button>
  </div>
</div>


<div class="container pt-5">

  <h2 class="text-center">WHAT WOULD YOU LIKE TO DO DIFFERENTLY?</h2>
  <p class="pt-3">
    At Pioneering Solutions Studio (or PSS), our experience with working with hundreds of multi-national companies has
    taught us one thing—education is a tool that drives progression. In our endeavour to empower people from across the
    globe through Education Technology (EdTech), we have developed an aptitude that allows us to position and deliver
    educational systems that challenge the conventions of traditional EdTech.
  </p>
  <p class="pt-3">
    Our proudly South African and internationally certified TRISCOMS&reg; system is the by-product of a culture that is
    driven by the belief that all people deserve access to quality education in a respected and professional
    environment. Whether it is at a grass-roots level, where we plant seeds for a better tomorrow or in our symbolic hot
    air balloon, where innovation sails towards new horizons, our company continues to reach new heights, expand
    boundaries and do what we do best—pioneer.
  </p>
  <p>
    TRISCOMS&reg; is used within industries for the Continuous Professional Development (CPD) under our ED.online SaaS
    portfolio. Corporates who need a one-stop-shop solution for the education of staff and education providers who sell
    educational course content, make use of our TRISCOMS&reg; Corporate and TRISCOMS&reg; Professional Education Partner
    PaaS
    solutions, respectively.
  </p>
  <p>Pioneering Solutions Studio is a proud Microsoft Gold and Authorised Education Partner.</p>
</div>

<div class="container p-5 my-5 border-box">

  <h1 class="text-center">OUR CLIENT PORTFOLIO</h1>
  <app-logo-carousel key="corporate"></app-logo-carousel>
  <app-logo-carousel key="educational"></app-logo-carousel>

</div>
<div class="container-fluid background-light  py-5">
  <div class="container ">

    <h1 class="text-center">WHAT ARE YOUR EDUCATION NEEDS?</h1>

    <p class="pt-3 text-center">
      Hybrid Education represents a way to offer tailor-made solutions for all type of Professionals and all types of
      learning styles.
    </p>

    <div class="product-cards card-deck ">
      <app-corporate-card class="mx-auto my-4"></app-corporate-card>
      <app-education-card class="mx-auto my-4"></app-education-card>
    </div>

    <p class="pt-3 text-center">
      Use TRISCOMS&reg; as a Platform to train your staff as a Corporate Employer
    </p>

    <div class="pt-3 text-center w-100">
      <div class="w-50 text-right" style="display: inline-block">
        <button class="btn btn-outline-dark rounded-0 m-2" routerLink="corporate-clients" fragment="corporate-pricing">PRICING</button>

      </div>
      <div class="w-50 text-left" style="display: inline-block">
        <button class="btn btn-dark rounded-0 m-2" routerLink="contact-us">REQUEST A DEMO</button>
      </div>
    </div>
  </div>
</div>
<div class="container my-5 d-none d-lg-block">

  <h1 class="text-center">TRISCOMS&reg; Functionality and Features</h1>

  <p class="pt-3 text-center">
    The diagram below shows how different elements of Hybrid Learning are managed by TRISCOMS&reg; and how the results
    thereof are consolidated in the Individual Professional Development Portfolio for individual Professionals and
    consolidated and reported in the Knowledge Center at programme or event level for Corporates and Professional
    Education Partners.
  </p>


  <div class="features-section font-weight-bold  mt-5 mx-auto">
    <div class=" row text-center">
      <div class="col-4">
        <video autoplay class="top-icon" loop muted poster="https://pssstaticwebstorage.blob.core.windows.net/images/vectors/lms-vector.png" width="100%">
          <source src="https://pssstaticwebstorage.blob.core.windows.net/images/vectors/lms-vector.webm" type="video/webm">
          <source src="https://pssstaticwebstorage.blob.core.windows.net/images/images/vectors/lms-vector.mp4" type="video/mp4">
          <p class="warning">Your browser does not support HTML5 video.</p>
        </video>

        <div class="text-center">
          ON DEMAND<br>
          ONLINE EDUCATION<br>
          via<br>
          learner management system (LMS)
        </div>
      </div>
      <div class="col-4">
        <video autoplay class="top-icon" loop muted poster="https://pssstaticwebstorage.blob.core.windows.net/images/vectors/virtual-campus-vector.png"
               width="100%">
          <source src="https://pssstaticwebstorage.blob.core.windows.net/images/vectors/virtual-campus-vector.webm" type="video/webm">
          <source src="https://pssstaticwebstorage.blob.core.windows.net/images/vectors/virtual-campus-vector.mp4" type="video/mp4">
          <p class="warning">Your browser does not support HTML5 video.</p>
        </video>

        <div class="text-center">
          INTERACTIVE<br>
          ONLINE EDUCATION<br>
          via<br>
          Virtual Campus
        </div>
      </div>
      <div class="col-4">
        <video autoplay class="top-icon" loop muted poster="https://pssstaticwebstorage.blob.core.windows.net/images/vectors/event-central-vector.png"
               width="100%">
          <source src="https://pssstaticwebstorage.blob.core.windows.net/images/vectors/event-central-vector.webm" type="video/webm">
          <source src="https://pssstaticwebstorage.blob.core.windows.net/images/vectors/event-central-vector.mp4" type="video/mp4">
          <p class="warning">Your browser does not support HTML5 video.</p>
        </video>
        <div class="text-center">
          FACE-TO-FACE<br>
          EDUCATION<br>
          via<br>
          Event Central
        </div>
      </div>
    </div>
    <div class="row text-center">

      <div class="col-4 text-right"><img alt="" class="side-arrow right-arrow"
                                         src="https://pssstaticwebstorage.blob.core.windows.net/images/vectors/arrow-right.png">
      </div>
      <div class="col-4">
        <img alt="" class="down-arrow" src="https://pssstaticwebstorage.blob.core.windows.net/images/vectors/arrow-down.png"><br>

        <video autoplay class="main-icon" loop muted poster="https://pssstaticwebstorage.blob.core.windows.net/images/vectors/individual-vector.png"
               width="100%">
          <source src="https://pssstaticwebstorage.blob.core.windows.net/images/vectors/individual-vector.webm" type="video/webm">
          <source src="https://pssstaticwebstorage.blob.core.windows.net/images/vectors/individual-vector.mp4" type="video/mp4">
          <p class="warning">Your browser does not support HTML5 video.</p>
        </video>
        <div class="text-center">
          EDUCATION CONSOLIDATION<br>
          via<br>
          Individual Professional Development Portfolio (LPP)
        </div>
      </div>
      <div class="col-4 text-left"><img alt="" class="side-arrow left-arrow"
                                        src="https://pssstaticwebstorage.blob.core.windows.net/images/vectors/arrow-left.png">
      </div>

    </div>

    <div class="row text-center">


      <div class="col-4 offset-4">
        <img alt="" class="down-arrow" src="https://pssstaticwebstorage.blob.core.windows.net/images/vectors/arrow-down.png"><br>
        <video autoplay class="main-icon" loop muted poster="https://pssstaticwebstorage.blob.core.windows.net/images/vectors/knowledge-center-vector.png"
               width="100%">
          <source src="https://pssstaticwebstorage.blob.core.windows.net/images/vectors/knowledge-center-vector.webm" type="video/webm">
          <source src="https://pssstaticwebstorage.blob.core.windows.net/images/vectors/knowledge-center-vector.mp4" type="video/mp4">
          <p class="warning">Your browser does not support HTML5 video.</p>
        </video>
        <div class="text-center">
          SPONSOR REPORTING<br>
          via<br>
          Knowledge Center
        </div>
      </div>


    </div>


  </div>

</div>

<div class="container-fluid background-hybrid text-center">
  <div class="container py-5">
    <h2>LEARN MORE ABOUT HYBRID EDUCATION</h2>
    <button class="btn btn-outline-light my-3 p-2 rounded-0" routerLink="functionality-and-features">LEARN MORE</button>
  </div>
</div>

<div class="what-we-keep-ourselves-busy blocks-container bg-info">


  <div>
    <div class="py-5 container ">

      <h2 class="text-center">SERVICES</h2>
      <p class="text-center pt-3">In addition to the provision of EDOnline and all its functionality, PSS further
        supports
        our Sponsors and Event Service Providers with the folloiwng value-added services.</p>

      <div class="service-tiles d-flex justify-content-center mx-auto flex-wrap flex-lg-nowrap">
        <div *ngFor="let tile of tiles" class="text-center service-tile m-md-5">
          <div class="white-link busy-icon text-center">


            <img [src]="'https://pssstaticwebstorage.blob.core.windows.net/' + tile.imageUrl" alt="{{tile.text}}" class="tq-img " fragment="{{tile.url}}"
                 routerLink="services">
            <p class="text-center mx-auto pt-2"><b>{{ tile.text}}</b>
            </p>
          </div>
        </div>
      </div>
      <p class="text-center">
        <button class="btn btn-outline-light" routerLink="services">LEARN MORE</button>
      </p>
    </div>
  </div>

</div>
