import {Component} from '@angular/core';
import {Meta, Title} from '@angular/platform-browser';
import {JsonLdService} from '../shared/json-ld.service';

@Component({
  selector: 'app-page-home',
  templateUrl: './page-home.component.html',
  styleUrls: ['./page-home.component.scss']
})
export class PageHomeComponent {

  tiles = [

    {
      text: 'Instructional Design',
      imageUrl: 'images/icons/instructional-course-design-icon.png',
      url: 'instructional-course-design',
      altText: 'Instructional Design Icon',
    },
    {
      text: 'Videography & Editing',
      imageUrl: 'images/icons/videography-editing-icon.png',
      url: 'videography-editing',
      altText: 'Videography & Editing Icon',
    },
    {
      text: 'Accredited Content Sourcing',
      imageUrl: 'images/icons/accredited-content-sourcing-icon.png',
      url: 'accredited-content-sourcing',
      altText: 'Accredited Content Sourcing Icon',
    },
    {
      text: 'Educational Game Development',
      imageUrl: 'images/icons/education-icon.png',
      url: 'education',
      altText: 'Educational Game Development Icon',
    },
    {
      text: 'Graphic Design Services',
      imageUrl: 'images/icons/graphic-design-icon.png',
      url: 'graphic-design',
      altText: 'Graphic Design Services Icon',
    },

  ];

  constructor(private titleService: Title, private metaService: Meta, private jsonLdService: JsonLdService) {

    titleService.setTitle('Empowering People Through Education Technology');

    jsonLdService.updateJson({
      '@context': 'http://schema.org',
      '@type': 'WebSite',
      name: 'Pioneering Solutions Studio (PSS)',
      url: 'https://www.ps.studio'
    });

    metaService.addTags([
      {name: 'keywords', content: 'Hybrid Education, EdTech'},
      {
        name: 'description',
        content: 'A Hybrid Education (EdTech) solution that offers tailor-made solutions for all type of Professionals and all types of learning styles. '
      },
      {name: 'robots', content: 'index, follow'}
    ]);
  }

}
