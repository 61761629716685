import {Component} from '@angular/core';
import {Meta, Title} from '@angular/platform-browser';
import {JsonLdService} from '../shared/json-ld.service';

@Component({
  selector: 'app-page-education',
  templateUrl: './page-education.component.html',
  styleUrls: ['./page-education.component.scss']
})
export class PageEducationComponent {

  constructor(private titleService: Title, private metaService: Meta, private jsonLdService: JsonLdService) {

    titleService.setTitle('Hybrid Education Tailor made for Professional Education Partners');

     jsonLdService.updateJson({
       '@context': 'http://schema.org',
       '@type': 'WebSite',
       name: 'Pioneering Solutions Studio (PSS)',
       url: 'https://www.ps.studio/professional-education-partners'
     });
    
     metaService.addTags([
       {name: 'keywords', content: 'Hybrid Education, Professional Education'},
       {name: 'description', content: 'Investment in Professional Education, educate your Staff or Clients via your own TRISCOMS® platform Platform as a Service for Employers'},
       {name: 'robots', content: 'index, follow'}
     ]);
  }

}
