import {Component, OnDestroy, OnInit} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
import {CanonicalService} from './shared/canonical.service';
import {JsonLdService} from './shared/json-ld.service';
import {NgcCookieConsentService, NgcStatusChangeEvent} from 'ngx-cookieconsent';
import {Subscription} from 'rxjs';

declare let gtag;
declare let fbq;
declare let ga;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'pss';
  schema;
  gaProperty = 'UA-135915904-1';
  private statusChangeSubscription: Subscription;
  private subscription;

  constructor(private ccService: NgcCookieConsentService, public router: Router, private canonicalService: CanonicalService, private jsonLdService: JsonLdService) {


    this.jsonLdService.schema.subscribe(val => this.schema = val);
    if (document.cookie.indexOf('cookieconsent_status=allow') > -1) {

      this.enableCookies();
    }
  }

  ngOnInit() {
    this.canonicalService.setCanonicalURL();

    this.statusChangeSubscription = this.ccService.statusChange$.subscribe(
      (event: NgcStatusChangeEvent) => {

        if (event.status === 'allow') {
          this.enableCookies();
        } else {
          this.disableCookies();
        }
      });


  }

  ngOnDestroy() {
    this.statusChangeSubscription.unsubscribe();
  }

  private enableCookies() {
    const disableStr = 'ga-disable-' + this.gaProperty;
    window[disableStr] = false;

    fbq('consent', 'grant');
    this.subscription = this.router.events.subscribe(event => {
        if (event instanceof NavigationEnd) {
          gtag('config', this.gaProperty,
            {
              page_path: event.urlAfterRedirects
            }
          );

          fbq('track', 'PageView');
        }
      }
    );
  }

  private disableCookies() {
    const disableStr = 'ga-disable-' + this.gaProperty;
    fbq('consent', 'revoke');
    window[disableStr] = true;

    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
