<div class="banner-image">

  <video autoplay height="100%" loop muted poster="https://pssstaticwebstorage.blob.core.windows.net/images/banners/about-us-1280.jpg" width="100%">
    <source src="https://pssstaticwebstorage.blob.core.windows.net/images/banners/about-us.webm" type="video/webm">
    <source src="https://pssstaticwebstorage.blob.core.windows.net/images/banners/about-us.mp4" type="video/mp4">
    <p class="warning">Your browser does not support HTML5 video.</p>
  </video>

  <div class="banner-text">
    <h1>ABOUT PSS</h1>
    <p>We live our vision of empowering people through EdTech with our TRISCOMS&reg; Platforms</p>
  </div>
</div>
<div class="container my-5 text-center">

  <h4>ABOUT PSS</h4>
  <p class="pt-3">
    PSS’ TRISCOMS&reg; framework is a leader in Education Technology (EdTech) and is used in multinational programs by
    organisations like the United Nations and private sector alike. TRISCOMS&reg; technology brings best-in-class
    solutions from various tech stack developers (including Microsoft) together in one comprehensive cloud-based
    Professional Development, Training and Education Platform. TRISCOMS&reg; empowers professionals, service providers,
    companies, professional associations, and councils across multiple industries and countries with innovative,
    integrated, and comprehensive online training and compliance solutions.
  </p>
</div>

<div class="container-fluid credentials-vision text-center">
  <div class="container py-5">
    <h4>We live our vision of empowering people through EdTech with TRISCOMS&reg;</h4>
    <p class="pt-3">
      TRISCOMS&reg; provides integrated
      Professional Compliance and Education Solutions to Professionals, Corporates and Professional Education Partners
      alike. TRISCOMS&reg; through our ED.online industry deployments, supports professional development in industries
      from healthcare to financial services and the built environment. TRISCOMS&reg; is cloud-based and leverages off
      the Microsoft Azure Cloud to provide reliability, scalability, and security under global standards. PSS is a
      Microsoft Gold Cloud Partner.
    </p>
  </div>
</div>

<div class="container-fluid py-5 bg-white text-center">
  <div class="container ">
    <h4>OUR GROUP HISTORY</h4>
    <p class="pt-3 mx-auto text-center">
      Learn why Professionals have logged over<br> 2.4 million Professional Education hours with us.
    </p>
  </div>
  <app-timeline></app-timeline>
</div>
<div class="container-fluid credentials-banner-image text-center">
  <div class="container py-5">
    <h1>OUR CREDENTIALS AND REACH</h1>
    <p class="pt-3">
      Pioneering Solutions Studio(PSS) is affiliated with The Foundation for Professional Development (registered with the Department of Higher Education and Training (DHET) as a Private Higher Education Institution (PHEI) in terms of Section 54(1)(c) of the Act and Regulation 16(4)(a)) and a strategic partner of The South African Medical Association (SAMA).
    </p>
    <p class="pt-3">
      We are a fully accredited Microsoft Partner and adhere to Microsoft International Standards during our
      solution-development
      life cycles. Our Microsoft Partner accreditations range from Authorised Education Partner (AEP) to Imagine Academy
      and Gold Cloud Platform, which enables us to innovatively implement leading Microsoft solutions into TRISCOMS&reg;.
    </p>
  </div>
</div>
