import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class CaptureMpcFormService {

  constructor(private http: HttpClient) {
  }

  submitCaptureForm(data) {

    if(data.between == ''){
      data.between = 'N/A';
    }

    if(data.andDate == ''){
      data.andDate = 'N/A';
    }
  
    if(data.weekdays){
      data.weekdays = 'Yes';
    } else{
      data.weekdays = 'No';
    }
  
    if(data.weekend){
      data.weekend = 'Yes';
    } else{
      data.weekend = 'No';
    }
  
    if(data.telephone){
      data.telephone = 'Yes';
    } else{
      data.telephone = 'No';
    }
  
    if(data.method_email){
      data.method_email = 'Yes';
    } else{
      data.method_email = 'No';
    }
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    
    var emailData = {
      subject : 'PSS: Standard Bank financing on products hosted on the MPC Online Store',
      replyTo : data.email,
      cc : ['MiederE@ps.studio','rihanab@ps.studio','DeWetB@ps.studio'],
      to : 'healthcare@standardbank.co.za',
      from : data.email, 
      senderId : 'psstudio',
      content : '<p>Dear Standard Bank Healthcare Specialist</p>'+
      '<p>The following user is requesting Standard Bank financing on products hosted on the MPC Online Store:</p>'+
      '<p>'+
        'Name: <b>' + data.fullName + '</b><br />'+
        'Surname: <b>' + data.surname + '</b><br />'+
        'Email: <b>' + data.email + '</b><br />'+
        'Contact Number: <b>' + data.contact + '</b><br /><br />'+

        'Suburb: <b>' + data.suburb + '</b><br />'+
        'Province: <b>' + data.province + '</b><br /><br />'+

        'When will it be most convenient for Standard Bank to contact you to discuss the financing application?: <br />'+
        'Between: <b>' + data.between + '</b>'+
        ' and: <b>' + data.andDate + '</b><br />'+
        'Weekdays: <b>' + data.weekdays + '</b><br/>'+
        'Weekend: <b>' + data.weekend + '</b><br /><br />'+

      'Preferred Contact Method:<br />'+
        'Email: <b>' + data.method_email + '</b><br/>'+
        'Telephone: <b>' + data.telephone + '</b><br /><br />'+

      '</p>'+
      '<p>Product information:'+
        '<br/>'+
        'Product_ID: <b>' + data.productId + '</b><br/>'+
        'Product_SKU: <b>' + data.productSku + '</b><br/>'+
        'Product_Name: <b>' + data.productName + '</b><br/>'+
      '</p>'
    }

    return this.http.post('https://sendgridserverless.azurewebsites.net/api/TriggerSendMail?code=Ea69V4SXbb215bT87a-AF1K0Sdl9MqV1ClQ0IKx26tqVAzFuwz4Z4g==', emailData, {  responseType: 'text'});

  }





  }


